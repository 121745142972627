const BASIC_INFO_STEPS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const PERSONAL_INFO_STEPS = [12, 13]
const BUSINESS_INFO_STEPS = [14, 15, 16]
const REVIEW_STEP = 20

export {
  BASIC_INFO_STEPS,
  PERSONAL_INFO_STEPS,
  BUSINESS_INFO_STEPS,
  REVIEW_STEP
}
