import { ReactElement } from 'react'

import { Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import { patchApplication } from '@/api/app.service'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { changeStep } from '@/redux/features/formStateSlice'
import EAppRoute from '@/types/appRoutes'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import BasicInformationDescriptions from './BasicInformationDescriptions'
import BusinessInformationDescriptions from './BusinessInformationDescriptions'
import BusinessOwnersDescriptions from './BusinessOwnersDescriptions'

import './ReviewStep.scss'

const ReviewStep = ({ form, next }: IStepProps): ReactElement => {
  const navigate = useNavigate()
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )
  const dispatch = useTypedDispatch()

  const [patch, isLoading] = usePatchApplication(next, async () => {
    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/isDraft',
          value: false
        }
      ])

      navigate(EAppRoute.Thanks, { replace: true })
      form.resetFields()
      dispatch(changeStep({ currentStep: 0 }))
    })
  })

  return (
    <Space direction='vertical' className={'review-step form-step'}>
      <h2 className={'review-step__title'}>Review</h2>
      <p className={'review-step__subtitle'}>Review before submitting</p>

      <BasicInformationDescriptions form={form} />
      <BusinessOwnersDescriptions form={form} />
      <BusinessInformationDescriptions form={form} />

      <Button
        loading={isLoading}
        type={'primary'}
        onClick={() => {
          patch()
        }}
        className={'review-step__next'}
      >
        Submit
      </Button>
    </Space>
  )
}

export default ReviewStep
