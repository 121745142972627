import React from 'react'

import { Button } from 'antd'

import BackgroundBlur from '@/components/icons/BackgroundBlur'
import { useTypedDispatch } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import {
  addHiddenStep,
  removeHiddenStep,
  setIsAgreedPrefill
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'

import './SaveTimeAi.scss'

// upload files and prefill data with Ai
const uploadWithAiSteps = [5, 6, 7, 8]
// default upload files
const uploadFilesSteps = [17, 18, 19]

const SaveTimeAi: React.FC<IStepProps> = ({ form, next }) => {
  const dispatch = useTypedDispatch()

  const handleNext = (isSkipLoading: boolean) => {
    if (isSkipLoading) {
      dispatch(addHiddenStep(uploadWithAiSteps))
      dispatch(removeHiddenStep(uploadFilesSteps))
      dispatch(setIsAgreedPrefill(false))
      patch()
    } else {
      dispatch(addHiddenStep(uploadFilesSteps))
      dispatch(removeHiddenStep(uploadWithAiSteps))
      dispatch(setIsAgreedPrefill(true))
      patch()
    }
  }

  const [patch, isLoading] = usePatchApplication(next, async () => {
    return
  })

  return (
    <div className={'save-time-ai form-step'}>
      <div className={'save-time-ai__info'}>
        <h2 className={'save-time-ai__title'}>
          Save time with our <span>AI tool</span>
        </h2>
        <p className={'save-time-ai__subtitle'}>
          Upload any of your most recent business/personal tax returns and
          driver's license and our AI-powered tool will extract the key details
          to speed up your application. Once uploaded, you’ll get personalized
          loan options tailored to your business.
        </p>
        <BackgroundBlur className={'save-time-ai__info__background-blur'} />
      </div>
      <Button
        type={'primary'}
        onClick={() => handleNext(false)}
        className={'save-time-ai__next'}
        loading={isLoading}
      >
        Upload Returns
      </Button>
      <Button
        type={'default'}
        onClick={() => handleNext(true)}
        className={'save-time-ai__skip'}
        loading={isLoading}
      >
        Skip
      </Button>
    </div>
  )
}

export default SaveTimeAi
