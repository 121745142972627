import { useEffect } from 'react'

import { useTypedDispatch } from '@/hooks/redux'
import { setOnBackToReviewClick } from '@/redux/features/formStateSlice'

const useSetOnBackToReviewClick = (
  callback: (value?: number) => Promise<void>
) => {
  const dispatch = useTypedDispatch()

  useEffect(() => {
    dispatch(setOnBackToReviewClick(callback))
  }, [callback, dispatch])
}

export default useSetOnBackToReviewClick
