import React from 'react'

import { Button, Form, Input, Radio } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { ANNUAL_REVENUE_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import dollarIcon from '@/images/icons/dollar.svg'
import { EAnnualRevenueType } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './AnnualProfit.scss'

const { annualProfitType, annualProfit } = FORM_FIELD_NAMES_MAP

const AnnualProfit: React.FC<IStepProps> = ({ form, next }) => {
  const annualProfitTypeValue = useWatch(annualProfitType, form)
  const annualProfitValue = useWatch(annualProfit, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  useSetPrefilledData(form, [annualProfit, annualProfitType])

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({
      annualProfitType,
      annualProfit
    }: {
      annualProfitType: string
      annualProfit: string | number
    }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/annualProfitType',
            value: annualProfitType
          }
        ])

        if (annualProfit) {
          await patchApplication(applicationId, [
            {
              path: '/annualProfit',
              value:
                typeof annualProfit === 'string'
                  ? annualProfit.replace(/,/g, '')
                  : annualProfit
            }
          ])
        }
      })
    }
  )

  return (
    <div className={'annual-profit form-step'}>
      <h2 className={'annual-profit__title'}>What is your annual profit?</h2>
      <p className={'annual-profit__subtitle'}>
        Please provide your estimated annual profit
      </p>
      <Form.Item
        name={annualProfitType}
        rules={[{ required: true }]}
        className={'annual-profit__radio-group'}
      >
        <Radio.Group disabled={annualProfitValue}>
          {ANNUAL_REVENUE_TYPE_OPTIONS.map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name={annualProfit}
        className='annual-revenue__input'
        label={'Annual profit'}
        rules={[
          {
            pattern: /^[0-9,]+$/,
            message: 'The amount must be a valid number with optional commas'
          }
        ]}
      >
        <Input
          placeholder='100,000'
          type='text'
          onChange={({ target: { value } }) => {
            const rawValue = value.replace(/,/g, '')

            let correspondingCheckBoxValue = EAnnualRevenueType.PreRevenue
            if (+rawValue <= 0) {
              correspondingCheckBoxValue = EAnnualRevenueType.PreRevenue
            } else if (+rawValue < 100000) {
              correspondingCheckBoxValue = EAnnualRevenueType.RevenueBelow100k
            } else if (+rawValue < 250000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue100k250k
            } else if (+rawValue < 500000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue250k500k
            } else if (+rawValue < 1000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue500k1m
            } else if (+rawValue < 2000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue1m2m
            } else if (+rawValue < 5000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue2m5m
            } else if (+rawValue >= 5000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.RevenueAbove5m
            }
            form.setFieldValue(annualProfitType, correspondingCheckBoxValue)

            const formattedValue = rawValue.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )
            form.setFieldValue(annualProfit, formattedValue)
          }}
          prefix={
            <img
              src={dollarIcon}
              alt='dollar'
              className='annual-revenue__prefix'
            />
          }
        />
      </Form.Item>

      <Button
        loading={isLoading}
        type={'primary'}
        onClick={() => {
          patch()
        }}
        className={'annual-profit__next'}
        disabled={!annualProfitValue && !annualProfitTypeValue}
      >
        Next
      </Button>
    </div>
  )
}

export default AnnualProfit
