import { ChangeEventHandler, ReactElement, useEffect, useState } from 'react'

import { Form, Input } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

import {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'

type TSSNFormItemProps = {
  name: number
  isPrimaryOwner: boolean
}

const { socialSecurityNumber } = OWNER_INFO_FIELD_NAMES_MAP
const { owners } = FORM_FIELD_NAMES_MAP

const SSNFormItem = ({
  name,
  isPrimaryOwner
}: TSSNFormItemProps): ReactElement => {
  const form = useFormInstance()
  const realValue =
    form.getFieldValue([owners, name, socialSecurityNumber]) ?? ''
  const [displayValue, setDisplayValue] = useState(
    '*'.repeat(realValue?.length)
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayValue(displayMaskedValue(realValue, false))
    }, 500)

    return () => clearTimeout(timeout)
  }, [realValue])

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async ({
    target: { value }
  }) => {
    let newValue
    let shouldShowLastDigit

    if (value.length >= realValue.length) {
      newValue = realValue + value.slice(realValue.length)
      shouldShowLastDigit = true
    } else {
      newValue = !value ? '' : realValue.slice(0, -1)
      shouldShowLastDigit = false
    }

    form.setFieldValue([owners, name, socialSecurityNumber], newValue)
    setDisplayValue(displayMaskedValue(newValue, shouldShowLastDigit))
  }

  const displayMaskedValue = (value: string, showLastDigit: boolean) => {
    if (!value) return ''
    const masked = '*'.repeat(value.length ? value.length - 1 : 0)
    return showLastDigit ? masked + value.slice(-1) : masked + '*'
  }

  return (
    <Form.Item
      getValueProps={() => ({ value: displayValue })}
      name={[name, socialSecurityNumber]}
      label='Social Security Number'
      className={'ownership__input'}
      style={{ width: '100%' }}
      rules={[
        {
          required: isPrimaryOwner,
          message: 'Please enter a Social Security Number'
        },
        {
          validator: () => {
            const isValid = !isPrimaryOwner || /(^\d{9}$)/.test(realValue)

            return isValid
              ? Promise.resolve()
              : Promise.reject(
                  new Error('Please enter a valid Social Security Number')
                )
          }
        }
      ]}
    >
      <Input
        placeholder='Enter Social Security Number'
        onChange={handleInputChange}
      />
    </Form.Item>
  )
}

export default SSNFormItem
