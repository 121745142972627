import { ReactElement } from 'react'

import { Button, Descriptions, DescriptionsProps, Divider } from 'antd'
import dayjs from 'dayjs'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import {
  FORM_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import {
  ANNUAL_REVENUE_TYPE_OPTIONS_MAP,
  FICO_SCORE_TYPE_OPTIONS_MAP,
  FUNDING_PURPOSE_OPTIONS_MAP,
  INDUSTRY_OPTIONS_MAP,
  TIMEFRAME_TYPE_OPTIONS_MAP
} from '@/constants/formFieldOptions'
import { BASIC_INFO_STEPS } from '@/constants/formSteps'
import { useTypedDispatch } from '@/hooks/redux'
import editIcon from '@/images/icons/edit-icon.svg'
import {
  changeStep,
  setDirection,
  toggleIsNavigatedFromReview
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'

const {
  fundingPurpose,
  fundingPurposeType,
  totalFunding,
  timeframeType,
  ficoScoreType,
  annualRevenueType
} = FORM_FIELD_NAMES_MAP

const {
  industryType,
  name,
  businessStartedAt,
  isFranchise: isFranchiseFieldName,
  hasGuarantors: hasGuarantorsFieldName,
  isStarted: isStartedFieldName
} = TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const BasicInformationDescriptions = ({
  form
}: Pick<IStepProps, 'form'>): ReactElement => {
  const dispatch = useTypedDispatch()

  const loanPurposeType = form.getFieldValue(fundingPurposeType)
  const loanPurpose = form.getFieldValue(fundingPurpose)
  const loanAmount = form.getFieldValue(totalFunding)
  const accessToFounds = form.getFieldValue(timeframeType)
  const ficoScore = form.getFieldValue(ficoScoreType)
  const annualRevenue = form.getFieldValue(annualRevenueType)
  const industry = form.getFieldValue(industryType)
  const businessName = form.getFieldValue(name)
  const businessStartDate = form.getFieldValue(businessStartedAt)
  const isFranchise = form.getFieldValue(isFranchiseFieldName)
  const hasGuarantors = form.getFieldValue(hasGuarantorsFieldName)
  const isStarted = form.getFieldValue(isStartedFieldName)

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Loan Purpose',
      children: loanPurpose ?? FUNDING_PURPOSE_OPTIONS_MAP[loanPurposeType]
    },
    {
      key: '2',
      label: 'Loan Amount',
      children: loanAmount ? `$${loanAmount}` : ''
    },
    {
      key: '3',
      label: 'Access to funds',
      children: TIMEFRAME_TYPE_OPTIONS_MAP[accessToFounds]
    },
    {
      key: '4',
      label: 'FICO Score',
      children: FICO_SCORE_TYPE_OPTIONS_MAP[ficoScore]
    },
    {
      key: '5',
      label: 'Annual revenue',
      children: ANNUAL_REVENUE_TYPE_OPTIONS_MAP[annualRevenue]
    },
    {
      key: '6',
      label: <Divider style={{ margin: 0 }} />,
      children: <Divider style={{ margin: 0 }} />
    },
    {
      key: '7',
      label: 'Business Name',
      children: businessName
    },
    {
      key: '8',
      label: 'Industry',
      children: INDUSTRY_OPTIONS_MAP[industry]
    },
    {
      key: '9',
      label: 'Date Business Started',
      children: businessStartDate
        ? dayjs(businessStartDate).format(DEFAULT_DATE_FORMAT.format)
        : ''
    }
  ]

  if (isStarted !== undefined) {
    items.push({
      key: '10',
      label: 'Business Started',
      children: isStarted ? 'Yes' : 'No'
    })
  }

  if (isFranchise !== undefined) {
    items.push({
      key: '11',
      label: 'Franchise',
      children: isFranchise ? 'Yes' : 'No'
    })
  }

  if (hasGuarantors !== undefined) {
    items.push({
      key: '12',
      label: 'Guarantors',
      children: hasGuarantors ? 'Yes' : 'No'
    })
  }

  return (
    <Descriptions
      className='review-step__descriptions'
      title='Basic Information'
      extra={
        <Button
          type='text'
          onClick={() => {
            dispatch(toggleIsNavigatedFromReview())
            dispatch(setDirection('backward'))

            setTimeout(() => {
              dispatch(changeStep({ currentStep: BASIC_INFO_STEPS[0] }))
            }, 100)
          }}
        >
          <img src={editIcon} alt='edit-icon' />
        </Button>
      }
      colon={false}
      column={1}
      items={items}
    />
  )
}

export default BasicInformationDescriptions
