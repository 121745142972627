import React, { useEffect, useRef, useState } from 'react'

import { Button, Progress } from 'antd'
import Lottie from 'lottie-react'

import { requestAutofill } from '@/api/app.service'
import APIinstance from '@/api/config'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import aiBackground from '@/images/ai-background.png'
import loadingAnimation from '@/images/loadingAnimation.json'
import { setPrefilledData } from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './AiLoading.scss'

const AiLoading: React.FC<IStepProps> = ({ form, next }) => {
  const [progress, setProgress] = useState(0)

  const abortControllerRef = useRef(new AbortController())

  const dispatch = useTypedDispatch()

  const [isAtLeastOneDocumentUploaded, applicationId] = useTypedSelector(
    ({ formState }) => [
      formState.isAtLeastOneDocumentUploaded,
      formState.applicationId
    ]
  )

  const [patch, isLoading] = usePatchApplication(next, async () => {
    await tryCatchWithMessage(async () => {
      return new Promise((res) => setTimeout(res, 1))
    })
  })

  useEffect(() => {
    if (isAtLeastOneDocumentUploaded) {
      requestAutofill(applicationId, abortControllerRef.current.signal)
        .then((response) => {
          dispatch(setPrefilledData(response))
          setProgress(100)
          patch()
        })
        .catch((error) => {
          console.log(error)
          patch()
        })
    } else {
      patch()
    }
  }, [])

  const handleCancelAutofill = () => {
    abortControllerRef.current.abort()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 95) {
          clearInterval(interval)
          return prev
        } else {
          return prev + Math.floor(Math.random() * 5)
        }
      })
    }, 780)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={'ai-loading form-step'}>
      <h2 className={'ai-loading__title'}>
        Save time with our <span>AI tool</span>
      </h2>
      <p className={'ai-loading__subtitle'}>
        Your form is being auto-filled, please wait.
      </p>
      <div className={'ai-loading__animation'}>
        <img
          src={aiBackground}
          alt={'Blue shadow'}
          className={'ai-loading__background'}
        />
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
      <p className={'ai-loading__subtitle'}>Your files are processing</p>
      <div className='ai-loading__progressbar-wrapper'>
        <Progress
          percent={progress}
          showInfo={false}
          size={{
            width: 240,
            height: 4
          }}
          strokeColor={{
            '0%': '#C27DB9',
            '25%': '#B2B2E0',
            '50%': '#B9A0DF',
            '100%': '#8FACD8'
          }}
        />
      </div>
      <Button
        onClick={handleCancelAutofill}
        loading={isLoading}
        className={'ai-loading__cancel-btn'}
      >
        Cancel
      </Button>
    </div>
  )
}

export default AiLoading
