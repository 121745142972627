import React from 'react'

import { Button, Form, Input } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication, submitApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import dollarIcon from '@/images/icons/dollar.svg'
import { setApplicationId } from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FundingAmount.scss'

const { totalFunding } = FORM_FIELD_NAMES_MAP

const FundingAmount: React.FC<IStepProps> = ({ form, next }) => {
  const fundingAmount = useWatch(totalFunding, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )
  const dispatch = useTypedDispatch()

  const [patch, isLoading] = usePatchApplication(next, async () => {
    await tryCatchWithMessage(async () => {
      const rawFundingAmount = Number(fundingAmount.replace(/,/g, ''))
      if (!applicationId) {
        const { id } = await submitApplication({
          isDraft: true,
          totalFunding: rawFundingAmount
        })

        id && dispatch(setApplicationId(id))
      } else {
        await patchApplication(applicationId, [
          {
            path: '/totalFunding',
            value: rawFundingAmount
          }
        ])
      }
    })
  })

  return (
    <div className={'funding-amount form-step'}>
      <h2 className={'funding-amount__title'}>How much funding do you need?</h2>
      <p className={'funding-amount__subtitle'}>
        Please specify how much funding you&apos;re looking for. Enter amount
        between $50,000 - $5,000,000
      </p>
      <Form.Item
        name={totalFunding}
        className='funding-amount__input'
        rules={[
          {
            required: true,
            message: 'Please enter an amount'
          },
          {
            pattern: /^[0-9,]+$/,
            message: 'The amount must be a valid number with optional commas'
          },
          {
            validator: (_, value) => {
              const sanitizedValue = value ? Number(value.replace(/,/g, '')) : 0
              if (
                !value ||
                (sanitizedValue >= 50000 && sanitizedValue <= 5000000)
              ) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  sanitizedValue < 50000
                    ? 'Min. amount should exceed $50,000'
                    : 'Max. amount should not exceed $5,000,000'
                )
              )
            }
          }
        ]}
      >
        <Input
          placeholder='100,000'
          type='text'
          onChange={({ target: { value } }) => {
            const rawValue = value.replace(/,/g, '')
            const formattedValue = rawValue.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )
            form.setFieldValue(totalFunding, formattedValue)
          }}
          prefix={
            <img
              src={dollarIcon}
              alt='dollar'
              className='funding-amount__prefix'
            />
          }
        />
      </Form.Item>

      <Button
        loading={isLoading}
        type={'primary'}
        onClick={() => {
          patch()
        }}
        className={'funding-amount__next'}
        disabled={
          !fundingAmount ||
          !(
            Number(fundingAmount.replace(/,/g, '').replace(/,/g, '')) >=
              50000 &&
            Number(fundingAmount.replace(/,/g, '').replace(/,/g, '')) <= 5000000
          )
        }
      >
        Next
      </Button>
    </div>
  )
}

export default FundingAmount
