import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StepTwoDesktop = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status)

  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M22.4163 23.25H19.083'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.75 24.9163V21.583'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.1332 16.0587C17.0498 16.0503 16.9498 16.0503 16.8582 16.0587C14.8748 15.992 13.2998 14.367 13.2998 12.367C13.2915 10.3253 14.9498 8.66699 16.9915 8.66699C19.0332 8.66699 20.6915 10.3253 20.6915 12.367C20.6915 14.367 19.1082 15.992 17.1332 16.0587Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9912 25.1751C15.4745 25.1751 13.9662 24.7917 12.8162 24.0251C10.7995 22.6751 10.7995 20.4751 12.8162 19.1334C15.1079 17.6001 18.8662 17.6001 21.1579 19.1334'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default StepTwoDesktop
