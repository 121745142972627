import { createSlice, current } from '@reduxjs/toolkit'

import { ApplicationDto } from '@/generated-types'

interface IFormStateInitialState {
  applicationId: string
  currentStep: number
  hiddenSteps: number[]
  isNavigatedFromReview: boolean
  stepsCount: number
  onBackToReviewClick?: (step?: number) => Promise<void>
  direction: 'forward' | 'backward'
  isAtLeastOneDocumentUploaded: boolean
  isAgreedPrefill: boolean
  prefilledData: any
}

const initialState: IFormStateInitialState = {
  applicationId: '',
  stepsCount: 0,
  currentStep: 0,
  hiddenSteps: [],
  isNavigatedFromReview: false,
  direction: 'forward',
  isAtLeastOneDocumentUploaded: false,
  isAgreedPrefill: false,
  prefilledData: {
    id: null,
    totalFunding: null,
    fundingPurposeType: null,
    fundingPurpose: null,
    timeframeType: null,
    ficoScoreType: null,
    annualRevenue: null,
    annualRevenueType: null,
    annualProfit: null,
    annualProfitType: null,
    targetBusinessOverview: {
      id: null,
      name: null,
      industryType: null,
      usaStateType: null,
      businessStartedAt: null,
      isStarted: true,
      isFranchise: true,
      hasGuarantors: true
    },
    owners: [
      {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        birthDate: null,
        businessWeight: null,
        isPrimary: true,
        address: {
          id: null,
          street: null,
          city: null,
          zipCode: null,
          usaStateType: null
        },
        socialSecurityNumber: null
      }
    ],
    businessOverview: {
      id: null,
      name: null,
      isDBA: false,
      dbaName: null,
      phoneNumber: null,
      address: {
        id: null,
        street: null,
        city: null,
        zipCode: null,
        usaStateType: null
      },
      businessStartedAt: null,
      businessEntityType: null,
      formationState: null,
      businessEIN: null
    },
    businessTaxReturns: [],
    personalTaxReturns: [],
    driverLicences: [],
    isDraft: false
  }
}

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    setDirection: (
      state,
      action: { payload: IFormStateInitialState['direction'] }
    ) => {
      state.direction = action.payload
    },
    setOnBackToReviewClick: (
      state,
      action: { payload: (step?: number) => Promise<void> }
    ) => {
      state.onBackToReviewClick = action.payload
    },
    setApplicationId: (state, action: { payload: string }) => {
      state.applicationId = action.payload
    },
    setStepsCount: (state, action: { payload: number }) => {
      state.stepsCount = action.payload
    },
    changeStep: (
      state,
      action: {
        payload:
          | {
              currentStep?: number
              direction?: IFormStateInitialState['direction']
            }
          | undefined
      }
    ) => {
      const isForwardDirection = action.payload?.direction === 'forward'
      const isBackwardDirection = action.payload?.direction === 'backward'
      const currentStep = action.payload?.currentStep ?? state.currentStep

      if (action.payload?.currentStep || !action.payload?.direction) {
        state.currentStep = currentStep
        return
      }

      let nextStep = currentStep + 1
      let prevStep = currentStep - 1

      if (isForwardDirection && nextStep < state.stepsCount) {
        while (current(state.hiddenSteps).includes(nextStep)) {
          nextStep++
        }

        state.currentStep = nextStep
      } else if (isBackwardDirection && prevStep >= 0) {
        while (current(state.hiddenSteps).includes(prevStep)) {
          prevStep--
        }
        state.currentStep = prevStep
      }
    },
    addHiddenStep: (state, action: { payload: number | number[] }) => {
      if (Array.isArray(action.payload)) {
        state.hiddenSteps = [...state.hiddenSteps, ...action.payload]
      } else {
        state.hiddenSteps = [...state.hiddenSteps, action.payload]
      }
    },
    removeHiddenStep: (state, action: { payload: number | number[] }) => {
      if (Array.isArray(action.payload)) {
        state.hiddenSteps = state.hiddenSteps.filter(
          (step) => !(action.payload as number[]).includes(step)
        )
      } else {
        state.hiddenSteps = state.hiddenSteps.filter(
          (step) => step !== action.payload
        )
      }
    },
    setIsNavigatedFromReview: (state, action: { payload: boolean }) => {
      state.isNavigatedFromReview = action.payload
    },
    toggleIsNavigatedFromReview: (state) => {
      state.isNavigatedFromReview = !state.isNavigatedFromReview
    },
    setIsAtLeastOneDocumentUploaded: (state, action: { payload: boolean }) => {
      state.isAtLeastOneDocumentUploaded = action.payload
    },
    setPrefilledData: (state, action: { payload: any }) => {
      state.prefilledData = action.payload
    },
    setIsAgreedPrefill: (state, action: { payload: boolean }) => {
      state.isAgreedPrefill = action.payload
    }
  }
})

export const {
  addHiddenStep,
  removeHiddenStep,
  toggleIsNavigatedFromReview,
  changeStep,
  setApplicationId,
  setStepsCount,
  setOnBackToReviewClick,
  setDirection,
  setIsAtLeastOneDocumentUploaded,
  setPrefilledData,
  setIsAgreedPrefill
} = formStateSlice.actions
export default formStateSlice.reducer
