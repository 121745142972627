import { ReactElement } from 'react'

type PlusIconProps = {
  disabled?: boolean
}

const PlusIcon = ({ disabled }: PlusIconProps): ReactElement => {
  const fill = disabled ? 'var(--primary-gray)' : 'var(--brand-primary)'
  const stroke = disabled ? 'var(--tertiary-gray)' : '#fff'

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='20' height='20' rx='10' fill={fill} />
      <path
        d='M9.9987 5.33301V14.6663M14.6654 9.99967L5.33203 9.99967'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default PlusIcon
