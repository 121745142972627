const getFormattedByCaseString = (
  value: string | undefined | null,
  fallback = ''
): string => {
  return value
    ? (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).replace(
        /_/g,
        ' '
      )
    : fallback
}

export default getFormattedByCaseString
