import { ReactElement } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { useIsMutating } from '@tanstack/react-query'
import { Button, Flex } from 'antd'

import ArrowBackIcon from '@/components/icons/ArrowBackIcon'
import { REVIEW_STEP } from '@/constants/formSteps'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import { toggleIsNavigatedFromReview } from '@/redux/features/formStateSlice'

const BackToReviewButton = (): ReactElement | null => {
  const isLoading = useIsMutating()

  const currentStep = useTypedSelector(({ formState }) => formState.currentStep)
  const { isNavigatedFromReview, onBackToReviewClick } = useTypedSelector(
    ({ formState }) => formState
  )

  const dispatch = useTypedDispatch()

  if (!isNavigatedFromReview || currentStep === REVIEW_STEP) {
    return null
  }

  return (
    <Flex align='center'>
      <Button
        loading={!!isLoading}
        size='large'
        className='back-to-review-button'
        icon={<ArrowBackIcon />}
        onClick={async () => {
          try {
            await onBackToReviewClick?.(REVIEW_STEP)
            dispatch(toggleIsNavigatedFromReview())
          } catch (e) {
            console.error(e)
          }
        }}
      >
        Back to Review
        <CloseOutlined
          className='close-icon'
          onClick={(e) => {
            e.stopPropagation()
            dispatch(toggleIsNavigatedFromReview())
          }}
        />
      </Button>
    </Flex>
  )
}

export default BackToReviewButton
