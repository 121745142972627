import React, { useEffect } from 'react'

import { Button, Form, Input, Radio } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { ANNUAL_REVENUE_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import dollarIcon from '@/images/icons/dollar.svg'
import { addHiddenStep } from '@/redux/features/formStateSlice'
import { EAnnualRevenueType } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './AnnualRevenue.scss'

const { annualRevenueType, annualRevenue } = FORM_FIELD_NAMES_MAP

const AnnualRevenue: React.FC<IStepProps> = ({ form, next }) => {
  const dispatch = useTypedDispatch()
  const annualRevenueTypeValue = useWatch(annualRevenueType, form)
  const annualRevenueValue = useWatch(annualRevenue, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  useEffect(() => {
    dispatch(addHiddenStep(8))
  }, [])

  useSetPrefilledData(form, [annualRevenue, annualRevenueType])

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({
      annualRevenueType,
      annualRevenue
    }: {
      annualRevenueType: string
      annualRevenue: string | number
    }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/annualRevenueType',
            value: annualRevenueType
          }
        ])

        if (annualRevenue) {
          await patchApplication(applicationId, [
            {
              path: '/annualRevenue',
              value:
                typeof annualRevenue === 'string'
                  ? annualRevenue.replace(/,/g, '')
                  : annualRevenue
            }
          ])
        }
      })
    }
  )

  return (
    <div className={'annual-revenue form-step'}>
      <h2 className={'annual-revenue__title'}>What is your annual revenue?</h2>
      <p className={'annual-revenue__subtitle'}>
        Please provide your estimated annual revenue
      </p>
      <Form.Item
        name={annualRevenueType}
        className={'annual-revenue__radio-group'}
      >
        <Radio.Group disabled={annualRevenueValue}>
          {ANNUAL_REVENUE_TYPE_OPTIONS.map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name={annualRevenue}
        className='annual-revenue__input'
        label={'Annual revenue'}
        rules={[
          {
            pattern: /^[0-9,]+$/,
            message: 'The amount must be a valid number with optional commas'
          }
        ]}
      >
        <Input
          placeholder='100,000'
          type='text'
          onChange={({ target: { value } }) => {
            const rawValue = value.replace(/,/g, '')

            let correspondingCheckBoxValue = EAnnualRevenueType.PreRevenue
            if (+rawValue <= 0) {
              correspondingCheckBoxValue = EAnnualRevenueType.PreRevenue
            } else if (+rawValue < 100000) {
              correspondingCheckBoxValue = EAnnualRevenueType.RevenueBelow100k
            } else if (+rawValue < 250000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue100k250k
            } else if (+rawValue < 500000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue250k500k
            } else if (+rawValue < 1000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue500k1m
            } else if (+rawValue < 2000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue1m2m
            } else if (+rawValue < 5000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.Revenue2m5m
            } else if (+rawValue >= 5000000) {
              correspondingCheckBoxValue = EAnnualRevenueType.RevenueAbove5m
            }
            form.setFieldValue(annualRevenueType, correspondingCheckBoxValue)

            const formattedValue = rawValue.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )
            form.setFieldValue(annualRevenue, formattedValue)
          }}
          prefix={
            <img
              src={dollarIcon}
              alt='dollar'
              className='annual-revenue__prefix'
            />
          }
        />
      </Form.Item>

      <Button
        loading={isLoading}
        type={'primary'}
        onClick={() => {
          patch()
        }}
        className={'annual-revenue__next'}
        disabled={!annualRevenueTypeValue && !annualRevenueValue}
      >
        Next
      </Button>
    </div>
  )
}

export default AnnualRevenue
