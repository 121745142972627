import { useMutation } from '@tanstack/react-query'

import useSetOnBackToReviewClick from '@/hooks/useSetOnBackToReviewClick'

const usePatchApplication = (
  next: <T>(
    apiCallback: (data: T) => Promise<void>,
    step?: number
  ) => Promise<void>,
  asyncCallback: (data: any) => Promise<void>
): [(number?: number) => Promise<void>, boolean] => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (step?: number) => {
      await next(asyncCallback, step)
    }
  })

  useSetOnBackToReviewClick(mutateAsync)

  return [mutateAsync, isPending]
}

export default usePatchApplication
