import { useEffect } from 'react'

import { FormInstance } from 'antd'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  addHiddenStep,
  removeHiddenStep
} from '@/redux/features/formStateSlice'
import { EFundingPurposeType } from '@/types/formFieldTypes'

import { useTypedDispatch, useTypedSelector } from './redux'

const { fundingPurposeType } = FORM_FIELD_NAMES_MAP

const useHiddenSteps = (form: FormInstance) => {
  const { hiddenSteps } = useTypedSelector(({ formState }) => formState)
  const dispatch = useTypedDispatch()

  const fundingPurpose = form.getFieldValue(fundingPurposeType)

  useEffect(() => {
    // Basic business info step
    const stepToHide = 11
    if (
      fundingPurpose === EFundingPurposeType.BuyBusiness ||
      fundingPurpose === EFundingPurposeType.StartBusiness
    ) {
      if (hiddenSteps.includes(stepToHide)) {
        dispatch(removeHiddenStep(stepToHide))
      }
      return
    }
    if (!hiddenSteps.includes(stepToHide)) {
      dispatch(addHiddenStep(stepToHide))
    }

    // AI Loading screen step
    // const aiLoadingStep = 8
    // if (hiddenSteps.includes(aiLoadingStep)) {
    //   dispatch(removeHiddenStep(aiLoadingStep))
    // }
    // if (!hiddenSteps.includes(aiLoadingStep)) {
    //   dispatch(addHiddenStep(aiLoadingStep))
    // }
  }, [dispatch, fundingPurpose, hiddenSteps])
}

export default useHiddenSteps
