import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StrepThreeDesktop = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status)

  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M13.6667 13.6667V12.8333C13.6667 10.9924 15.1591 9.5 17 9.5C18.8409 9.5 20.3333 10.9924 20.3333 12.8333V13.6667M9.5 17L16.3231 20.0325C16.754 20.224 17.246 20.224 17.6769 20.0325L24.5 17M12.1667 24.5H21.8333C22.7668 24.5 23.2335 24.5 23.59 24.3183C23.9036 24.1586 24.1586 23.9036 24.3183 23.59C24.5 23.2335 24.5 22.7668 24.5 21.8333V16.3333C24.5 15.3999 24.5 14.9332 24.3183 14.5767C24.1586 14.2631 23.9036 14.0081 23.59 13.8483C23.2335 13.6667 22.7668 13.6667 21.8333 13.6667H12.1667C11.2332 13.6667 10.7665 13.6667 10.41 13.8483C10.0964 14.0081 9.84144 14.2631 9.68166 14.5767C9.5 14.9332 9.5 15.3999 9.5 16.3333V21.8333C9.5 22.7668 9.5 23.2335 9.68166 23.59C9.84144 23.9036 10.0964 24.1586 10.41 24.3183C10.7665 24.5 11.2332 24.5 12.1667 24.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default StrepThreeDesktop
