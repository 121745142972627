import React from 'react'

import { Button, Form, Radio } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { FICO_SCORE_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FicoScore.scss'

const { ficoScoreType } = FORM_FIELD_NAMES_MAP

const FicoScore: React.FC<IStepProps> = ({ form, next }) => {
  const ficoScore = useWatch(ficoScoreType, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ ficoScoreType }: { ficoScoreType: string }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/ficoScoreType',
            value: ficoScoreType
          }
        ])
      })
    }
  )

  return (
    <div className={'fico-score form-step'}>
      <h2 className={'fico-score__title'}>What is your FICO score?</h2>
      <p className={'fico-score__subtitle'}>Choose one of the options below</p>
      <Form.Item
        name={ficoScoreType}
        rules={[{ required: true }]}
        className={'fico-score__radio-group'}
      >
        <Radio.Group>
          {FICO_SCORE_TYPE_OPTIONS.map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Button
        loading={isLoading}
        type={'primary'}
        onClick={() => {
          patch()
        }}
        className={'fico-score__next'}
        disabled={!ficoScore}
      >
        Next
      </Button>
    </div>
  )
}

export default FicoScore
