import { FC } from 'react'

import useShouldShowRejectedView from '@/hooks/useShouldShowRejectedView'
import { IStepProps } from '@/types/types'

import PartiallyApprovedView from './PartiallyApprovedView'
import RejectedView from './RejectedView'

import './PersonalInfo.scss'

const PersonalInfo: FC<IStepProps> = ({ form }) => {
  const shouldShowRejectedView = useShouldShowRejectedView(form)

  if (shouldShowRejectedView) {
    return <RejectedView />
  }

  return <PartiallyApprovedView />
}

export default PersonalInfo
