import { ReactNode, useEffect } from 'react'

import AccessTime from '@/components/steps/access-time/AccessTime'
import AiLoading from '@/components/steps/ai-loading/AiLoading'
import AnnualProfit from '@/components/steps/annual-profit/AnnualProfit'
import AnnualRevenue from '@/components/steps/annual-revenue/AnnualRevenue'
import BusinessInfoFirstStep from '@/components/steps/business-info-first-step/BusinessInfoFirstStep'
import BusinessInfoSecondStep from '@/components/steps/business-info-second-step/BusinessInfoSecondStep'
import BusinessInfoThirdStep from '@/components/steps/business-info-third-step/BusinessInfoThirdStep'
import BusinessInformation from '@/components/steps/business-information/BusinessInformation'
import BusinessTax from '@/components/steps/business-tax/BusinessTax'
import DriversLicense from '@/components/steps/drivers-license/DriversLicense'
import FicoScore from '@/components/steps/fico-score/FicoScore'
import FundingAmount from '@/components/steps/funding-amount/FundingAmount'
import FundsFor from '@/components/steps/funds-for/FundsFor'
import OwnerInfo from '@/components/steps/owner-info/OwnerInfo'
import PersonalInfo from '@/components/steps/personal-info/PersonalInfo'
import PersonalTax from '@/components/steps/personal-tax/PersonalTax'
import ReviewStep from '@/components/steps/review-step/ReviewStep'
import SaveTimeAi from '@/components/steps/save-time-ai/SaveTimeAi'
import { useTypedDispatch } from '@/hooks/redux'
import { setStepsCount } from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'

const useFormSteps = ({
  form,
  next
}: IStepProps): { title: string; content: ReactNode }[] => {
  const dispatch = useTypedDispatch()

  const steps = [
    {
      title: 'Basic Info',
      content: <FundingAmount form={form} next={next} />
    },
    {
      title: 'Funds Purpose',
      content: <FundsFor form={form} next={next} />
    },
    {
      title: 'Access Time',
      content: <AccessTime form={form} next={next} />
    },
    {
      title: 'Fico Score',
      content: <FicoScore form={form} next={next} />
    },
    {
      title: 'Save Time AI',
      content: <SaveTimeAi form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <BusinessTax form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <PersonalTax form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <DriversLicense form={form} next={next} />
    },
    {
      title: 'AI Loading',
      content: <AiLoading form={form} next={next} />
    },
    {
      title: 'Annual Revenue',
      content: <AnnualRevenue form={form} next={next} />
    },
    {
      title: 'Annual Profit',
      content: <AnnualProfit form={form} next={next} />
    },
    {
      title: 'Business Information',
      content: <BusinessInformation form={form} next={next} />
    },
    {
      title: 'Personal Info',
      content: <PersonalInfo form={form} next={next} />
    },
    {
      title: 'Owner Info',
      content: <OwnerInfo form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <BusinessInfoFirstStep form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <BusinessInfoSecondStep form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <BusinessInfoThirdStep form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <BusinessTax form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <PersonalTax form={form} next={next} />
    },
    {
      title: 'Business Info',
      content: <DriversLicense form={form} next={next} />
    },
    {
      title: 'Review',
      content: <ReviewStep form={form} next={next} />
    }
  ]

  useEffect(() => {
    dispatch(setStepsCount(steps.length))
  }, [dispatch, steps.length])

  return steps
}

export default useFormSteps
