import {
  EAnnualRevenueType,
  EBusinessEntity,
  EFicoScoreType,
  EFundingPurposeType,
  EIndustryType,
  ETimeframeType,
  EUsaState,
  TFormSelectOption
} from '@/types/formFieldTypes'
import getFormattedByCaseString from '@/utils/getFormattedByCaseString'

const USA_STATE_OPTIONS: { label: string; value: EUsaState }[] = [
  { label: 'Alabama', value: EUsaState.AL },
  { label: 'Alaska', value: EUsaState.AK },
  { label: 'Arizona', value: EUsaState.AZ },
  { label: 'Arkansas', value: EUsaState.AR },
  { label: 'California', value: EUsaState.CA },
  { label: 'Colorado', value: EUsaState.CO },
  { label: 'Connecticut', value: EUsaState.CT },
  { label: 'Delaware', value: EUsaState.DE },
  { label: 'District of Columbia', value: EUsaState.DC },
  { label: 'Florida', value: EUsaState.FL },
  { label: 'Georgia', value: EUsaState.GA },
  { label: 'Hawaii', value: EUsaState.HI },
  { label: 'Idaho', value: EUsaState.ID },
  { label: 'Illinois', value: EUsaState.IL },
  { label: 'Indiana', value: EUsaState.IN },
  { label: 'Iowa', value: EUsaState.IA },
  { label: 'Kansas', value: EUsaState.KS },
  { label: 'Kentucky', value: EUsaState.KY },
  { label: 'Louisiana', value: EUsaState.LA },
  { label: 'Maine', value: EUsaState.ME },
  { label: 'Maryland', value: EUsaState.MD },
  { label: 'Massachusetts', value: EUsaState.MA },
  { label: 'Michigan', value: EUsaState.MI },
  { label: 'Minnesota', value: EUsaState.MN },
  { label: 'Mississippi', value: EUsaState.MS },
  { label: 'Missouri', value: EUsaState.MO },
  { label: 'Montana', value: EUsaState.MT },
  { label: 'Nebraska', value: EUsaState.NE },
  { label: 'Nevada', value: EUsaState.NV },
  { label: 'New Hampshire', value: EUsaState.NH },
  { label: 'New Jersey', value: EUsaState.NJ },
  { label: 'New Mexico', value: EUsaState.NM },
  { label: 'New York', value: EUsaState.NY },
  { label: 'North Carolina', value: EUsaState.NC },
  { label: 'North Dakota', value: EUsaState.ND },
  { label: 'Ohio', value: EUsaState.OH },
  { label: 'Oklahoma', value: EUsaState.OK },
  { label: 'Oregon', value: EUsaState.OR },
  { label: 'Pennsylvania', value: EUsaState.PA },
  { label: 'Puerto Rico', value: EUsaState.PR },
  { label: 'Rhode Island', value: EUsaState.RI },
  { label: 'South Carolina', value: EUsaState.SC },
  { label: 'South Dakota', value: EUsaState.SD },
  { label: 'Tennessee', value: EUsaState.TN },
  { label: 'Texas', value: EUsaState.TX },
  { label: 'Utah', value: EUsaState.UT },
  { label: 'Vermont', value: EUsaState.VT },
  { label: 'Virginia', value: EUsaState.VA },
  { label: 'Washington', value: EUsaState.WA },
  { label: 'West Virginia', value: EUsaState.WV },
  { label: 'Wisconsin', value: EUsaState.WI },
  { label: 'Wyoming', value: EUsaState.WY }
]

const USA_STATE_OPTIONS_MAP = USA_STATE_OPTIONS.reduce<TFormSelectOption>(
  (acc, { value, label }) => {
    acc[value] = label
    return acc
  },
  {} as TFormSelectOption
)

const TIMEFRAME_TYPE_OPTIONS = [
  { label: 'Immediately', value: ETimeframeType.immediately },
  { label: 'Within 2-4 Weeks', value: ETimeframeType.TwoForWeeks },
  { label: '4 Weeks+', value: ETimeframeType.FourWeeksPlus }
]

const TIMEFRAME_TYPE_OPTIONS_MAP =
  TIMEFRAME_TYPE_OPTIONS.reduce<TFormSelectOption>((acc, { value, label }) => {
    acc[value] = label
    return acc
  }, {} as TFormSelectOption)

const ANNUAL_REVENUE_TYPE_OPTIONS = [
  { label: 'Pre-Revenue', value: EAnnualRevenueType.PreRevenue },
  { label: '<$100k', value: EAnnualRevenueType.RevenueBelow100k },
  { label: '$100k-$250k', value: EAnnualRevenueType.Revenue100k250k },
  { label: '$250k-$500k', value: EAnnualRevenueType.Revenue250k500k },
  { label: '$500k-$1m', value: EAnnualRevenueType.Revenue500k1m },
  { label: '$1m-$2m', value: EAnnualRevenueType.Revenue1m2m },
  { label: '$2m-$5m', value: EAnnualRevenueType.Revenue2m5m },
  { label: '$5m+', value: EAnnualRevenueType.RevenueAbove5m }
]

const ANNUAL_REVENUE_TYPE_OPTIONS_MAP =
  ANNUAL_REVENUE_TYPE_OPTIONS.reduce<TFormSelectOption>(
    (acc, { value, label }) => {
      acc[value] = label
      return acc
    },
    {} as TFormSelectOption
  )

const BUSINESS_ENTITY_OPTIONS: { label: string; value: EBusinessEntity }[] = [
  {
    label: 'C-Corp',
    value: EBusinessEntity.CCorp
  },
  {
    label: 'S-Corp',
    value: EBusinessEntity.SCorp
  },
  {
    label: 'LLC',
    value: EBusinessEntity.LLC
  },
  {
    label: 'Sole Proprietor',
    value: EBusinessEntity.SoleProprietor
  }
]

const BUSINESS_ENTITY_OPTIONS_MAP =
  BUSINESS_ENTITY_OPTIONS.reduce<TFormSelectOption>((acc, { value, label }) => {
    acc[value] = label
    return acc
  }, {} as TFormSelectOption)

const INDUSTRY_OPTIONS = Object.values(EIndustryType).map((value) => ({
  label: getFormattedByCaseString(value),
  value
}))

const INDUSTRY_OPTIONS_MAP = INDUSTRY_OPTIONS.reduce<TFormSelectOption>(
  (acc, { value, label }) => {
    acc[value] = label
    return acc
  },
  {} as TFormSelectOption
)

const FICO_SCORE_TYPE_OPTIONS = [
  { label: '850-800', value: EFicoScoreType.Excellent },
  { label: '799-740', value: EFicoScoreType.Good },
  { label: '739-676', value: EFicoScoreType.Average },
  { label: '675-640', value: EFicoScoreType.Fair },
  { label: '639-580', value: EFicoScoreType.Poor },
  { label: '579-300', value: EFicoScoreType.VeryPoor }
]

const FICO_SCORE_TYPE_OPTIONS_MAP =
  FICO_SCORE_TYPE_OPTIONS.reduce<TFormSelectOption>((acc, { value, label }) => {
    acc[value] = label
    return acc
  }, {} as TFormSelectOption)

const FUNDING_PURPOSE_OPTIONS = [
  { label: 'Start a Business', value: EFundingPurposeType.StartBusiness },
  { label: 'Buy a Business', value: EFundingPurposeType.BuyBusiness },
  {
    label: 'Buy a Commercial Real Estate',
    value: EFundingPurposeType.BuyCommercialRealEstate
  },
  { label: 'Business Expansion', value: EFundingPurposeType.BusinessExpansion },
  { label: 'Cash Crunch', value: EFundingPurposeType.CashCrunch },
  { label: 'Other', value: EFundingPurposeType.Other }
]

const FUNDING_PURPOSE_OPTIONS_MAP =
  FUNDING_PURPOSE_OPTIONS.reduce<TFormSelectOption>((acc, { value, label }) => {
    acc[value] = label
    return acc
  }, {} as TFormSelectOption)

export {
  USA_STATE_OPTIONS,
  USA_STATE_OPTIONS_MAP,
  TIMEFRAME_TYPE_OPTIONS,
  TIMEFRAME_TYPE_OPTIONS_MAP,
  ANNUAL_REVENUE_TYPE_OPTIONS,
  ANNUAL_REVENUE_TYPE_OPTIONS_MAP,
  BUSINESS_ENTITY_OPTIONS,
  BUSINESS_ENTITY_OPTIONS_MAP,
  INDUSTRY_OPTIONS,
  INDUSTRY_OPTIONS_MAP,
  FICO_SCORE_TYPE_OPTIONS,
  FICO_SCORE_TYPE_OPTIONS_MAP,
  FUNDING_PURPOSE_OPTIONS,
  FUNDING_PURPOSE_OPTIONS_MAP
}
