import { ReactElement } from 'react'

const StepDesktopCompleted = (): ReactElement => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        fill='var(--brand-secondary)'
      />
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke='var(--brand-primary)'
        strokeWidth='1.5'
      />
      <path
        d='M23.6663 12.834L15.0889 21.4114C14.7635 21.7368 14.2359 21.7368 13.9104 21.4114L10.333 17.834'
        stroke='var(--brand-primary)'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default StepDesktopCompleted
