import { ReactElement } from 'react'

const StarCheckIcon = (): ReactElement => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.8418 2.19423C12.4418 1.46512 13.5582 1.46512 14.1582 2.19424L15.5188 3.84747C16.0478 4.49032 16.8621 4.82763 17.6908 4.74715L19.8218 4.54019C20.7617 4.44891 21.5511 5.23831 21.4598 6.17816L21.2529 8.30922C21.1724 9.13786 21.5097 9.9522 22.1525 10.4812L23.8058 11.8418C24.5349 12.4418 24.5349 13.5582 23.8058 14.1582L22.1525 15.5188C21.5097 16.0478 21.1724 16.8621 21.2529 17.6908L21.4598 19.8218C21.5511 20.7617 20.7617 21.5511 19.8218 21.4598L17.6908 21.2529C16.8621 21.1724 16.0478 21.5097 15.5188 22.1525L14.1582 23.8058C13.5582 24.5349 12.4418 24.5349 11.8418 23.8058L10.4812 22.1525C9.9522 21.5097 9.13786 21.1724 8.30922 21.2529L6.17815 21.4598C5.23831 21.5511 4.44891 20.7617 4.54019 19.8218L4.74715 17.6908C4.82763 16.8621 4.49032 16.0478 3.84747 15.5188L2.19423 14.1582C1.46512 13.5582 1.46512 12.4418 2.19424 11.8418L3.84747 10.4812C4.49032 9.9522 4.82763 9.13786 4.74715 8.30922L4.54019 6.17815C4.44891 5.23831 5.23831 4.44891 6.17816 4.54019L8.30922 4.74715C9.13786 4.82763 9.9522 4.49032 10.4812 3.84747L11.8418 2.19423Z'
        fill='var(--brand-secondary)'
        stroke='var(--brand-primary)'
      />
      <path
        d='M17.8283 10.5859L12.0937 16.3205C11.8762 16.5381 11.5234 16.5381 11.3058 16.3205L8.91406 13.9288'
        stroke='var(--brand-primary)'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default StarCheckIcon
