import {
  USA_STATE_OPTIONS,
  USA_STATE_OPTIONS_MAP
} from '@/constants/formFieldOptions'
import { AddressDto } from '@/generated-types'

const getFormattedAddress = (address: AddressDto | undefined): string => {
  if (!address) return ''

  const street = address.street ? `${address.street}, ` : ''
  const city = address.city ? `${address.city}, ` : ''
  const state = address.usaStateType
    ? `${
        USA_STATE_OPTIONS.find((state) => state.label === address.usaStateType)
          ?.label
      }, `
    : ''
  const zipCode = address.zipCode ? address.zipCode : ''

  return `${street}${city}${state}${zipCode}`.trim()
}

export default getFormattedAddress
