import { ReactElement, useEffect } from 'react'

import { Button, Form, Radio, Space } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import { uploadFiles } from '@/api/file.service'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import {
  removeHiddenStep,
  setIsAtLeastOneDocumentUploaded
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessTax.scss'

const { businessTaxReturns, businessTaxReturnsAccess } = FORM_FIELD_NAMES_MAP

const BusinessTax = ({ form, next }: IStepProps): ReactElement => {
  const businessTaxFiles = useWatch(businessTaxReturns, form)
  const access = useWatch(businessTaxReturnsAccess, form)
  const dispatch = useTypedDispatch()

  useEffect(() => {
    if (!access) form.setFieldValue(businessTaxReturns, undefined)
  }, [access, form])

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ businessTaxReturns = [] }) => {
      if (!businessTaxReturns.length) return

      await tryCatchWithMessage(async () => {
        const uploadedBusinessTaxReturns =
          (await uploadFiles(businessTaxReturns)) || []

        await patchApplication(applicationId, [
          {
            path: '/businessTaxReturns',
            value: uploadedBusinessTaxReturns
          }
        ])
        dispatch(setIsAtLeastOneDocumentUploaded(true))
        dispatch(removeHiddenStep(8))
      })
    }
  )

  return (
    <div className={'business-tax form-step'}>
      <h2 className={'business-tax__title'}>
        Do you have access to your business tax returns?
      </h2>
      <p className={'business-tax__subtitle'}>
        All loans offers require these to be uploaded, by adding earlier in the
        process you increase your chances of securing a loan faster
      </p>

      <Space direction='vertical' size='large'>
        <div>
          <Form.Item
            name={businessTaxReturnsAccess}
            rules={[
              {
                required: true,
                message: 'This field is required'
              }
            ]}
            className={'business-information__radio-group'}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        {access && (
          <div>
            <h3 className={'business-tax__form-label'}>
              Upload up to 10 of your most recent Business tax returns.
            </h3>
            <FormUploadInput
              name={businessTaxReturns}
              fileList={businessTaxFiles}
            />
          </div>
        )}
      </Space>
      <Button
        type={'primary'}
        onClick={() => {
          patch()
        }}
        loading={isLoading}
        disabled={access === undefined}
        className={'business-tax__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default BusinessTax
