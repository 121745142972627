import { ReactElement } from 'react'

import StepDesktopCompleted from '@/components/icons/StepDesktopCompleted'
import StepFourDesktop from '@/components/icons/StepFourDesktop'
import StepMobile from '@/components/icons/StepMobile'
import StepMobileCompleted from '@/components/icons/StepMobileCompleted'
import StepOneDesktop from '@/components/icons/StepOneDesktop'
import StepThreeDesktop from '@/components/icons/StepThreeDesktop'
import StepTwoDesktop from '@/components/icons/StepTwoDesktop'

interface ITimeLineStep {
  key:
    | 'basic-information'
    | 'personal-information'
    | 'business-information'
    | 'review'
  notActiveMobileIcon: ReactElement
  activeMobileIcon: ReactElement
  completedMobileIcon: ReactElement
  notActiveDesktopIcon: ReactElement
  activeDesktopIcon: ReactElement
  completedDesktopIcon: ReactElement
  mobileTitle: string
  desktopTitle: string
  description: string
}

export const timeLineSteps: ITimeLineStep[] = [
  {
    key: 'basic-information',
    notActiveMobileIcon: <StepMobile />,
    activeMobileIcon: <StepMobile status='active' />,
    completedMobileIcon: <StepMobileCompleted />,
    notActiveDesktopIcon: <StepOneDesktop />,
    activeDesktopIcon: <StepOneDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    mobileTitle: 'Basic Info',
    desktopTitle: 'Basic Information',
    description: 'Provide loan details'
  },
  {
    key: 'personal-information',
    notActiveMobileIcon: <StepMobile />,
    activeMobileIcon: <StepMobile status='active' />,
    completedMobileIcon: <StepMobileCompleted />,
    notActiveDesktopIcon: <StepTwoDesktop />,
    activeDesktopIcon: <StepTwoDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    mobileTitle: 'Personal Info',
    desktopTitle: 'Personal Information',
    description: 'Provide personal details'
  },
  {
    key: 'business-information',
    notActiveMobileIcon: <StepMobile />,
    activeMobileIcon: <StepMobile status='active' />,
    completedMobileIcon: <StepMobileCompleted />,
    notActiveDesktopIcon: <StepThreeDesktop />,
    activeDesktopIcon: <StepThreeDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    mobileTitle: 'Business Info',
    desktopTitle: 'Business Information',
    description: 'Provide essential business data'
  },
  {
    key: 'review',
    notActiveMobileIcon: <StepMobile />,
    activeMobileIcon: <StepMobile status='active' />,
    completedMobileIcon: <StepMobileCompleted />,
    notActiveDesktopIcon: <StepFourDesktop />,
    activeDesktopIcon: <StepFourDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    mobileTitle: 'Review',
    desktopTitle: 'Review',
    description: 'Verify information for accuracy'
  }
]
