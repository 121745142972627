import { ReactElement } from 'react'

const RejectedView = (): ReactElement => {
  return (
    <div className='full-screen-block form-step'>
      <p className={'owner-info__subtitle partially-approved'}>
        Based on the information you provided, we currently do not have an
        option available for you.
      </p>
    </div>
  )
}

export default RejectedView
