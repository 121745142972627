import { createQueryKeyStore } from '@lukemorales/query-key-factory'

import { PartnerDto } from '@/generated-types'

import APIinstance from './config'

export const partnersQueries = createQueryKeyStore({
  partner: {
    me: ['partner/me']
  }
})

export const getPartnerConfig = async (): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.get<Required<PartnerDto>>('partners/me')
  return data
}
