import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StepOneDesktop = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status)

  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        fill='var(--brand-secondary)'
      />
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M20.4614 12.9617C19.6706 12.1708 18.2789 11.6217 16.9998 11.5867M16.9998 11.5867C15.4781 11.5458 14.1156 12.235 14.1156 14.115C14.1156 17.5767 20.4614 15.8458 20.4614 19.3075C20.4614 21.2825 18.7723 22.1308 16.9998 22.0667M16.9998 11.5867V9.5M13.5381 20.4617C14.2823 21.4533 15.6648 22.0183 16.9998 22.0667M16.9998 22.0667V24.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default StepOneDesktop
