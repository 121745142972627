import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StepMobile = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status, true)

  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='20.5'
        height='20.5'
        rx='10.25'
        fill='white'
      />
      <rect
        x='0.75'
        y='0.75'
        width='20.5'
        height='20.5'
        rx='10.25'
        stroke={color}
        strokeWidth='1.5'
      />
      {status && status !== 'not-active' && (
        <rect x='6' y='6' width='10' height='10' rx='5' fill={color} />
      )}
    </svg>
  )
}

export default StepMobile
