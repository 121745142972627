import { TStepIconProps } from '@/types/stepIcon'

const getStepIconColor = (
  status: TStepIconProps['status'],
  isMobile = false
): string => {
  switch (status) {
    case 'active':
      return 'var(--brand-primary)'
    case 'completed':
      return 'var(--brand-primary)'
    default:
      return isMobile ? 'var(--primary-gray)' : 'var(--tertiary-gray)'
  }
}

export default getStepIconColor
