import { ReactElement } from 'react'

import { Button, Descriptions, DescriptionsProps } from 'antd'
import { PhoneNumber } from 'antd-phone-input'
import dayjs from 'dayjs'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  BUSINESS_ENTITY_OPTIONS_MAP,
  USA_STATE_OPTIONS,
  USA_STATE_OPTIONS_MAP
} from '@/constants/formFieldOptions'
import { BUSINESS_INFO_STEPS } from '@/constants/formSteps'
import { BusinessOverviewDto } from '@/generated-types'
import { useTypedDispatch } from '@/hooks/redux'
import editIcon from '@/images/icons/edit-icon.svg'
import {
  changeStep,
  setDirection,
  toggleIsNavigatedFromReview
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import getFormattedAddress from '@/utils/getFormattedAddress'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'

const { businessOverview } = FORM_FIELD_NAMES_MAP

const BusinessInformationDescriptions = ({
  form
}: Pick<IStepProps, 'form'>): ReactElement => {
  const dispatch = useTypedDispatch()

  const {
    name,
    address,
    phoneNumber,
    businessStartedAt,
    businessEntityType,
    formationState,
    businessEIN
  }: Omit<BusinessOverviewDto, 'phoneNumber'> & {
    phoneNumber: string | PhoneNumber
  } = form.getFieldValue(businessOverview) || {}

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Business Name',
      children: name
    },
    {
      key: '2',
      label: 'Address',
      children: getFormattedAddress(address)
    },
    {
      key: '3',
      label: 'Business Phone Number',
      children: getFormattedPhoneNumber(phoneNumber)
    },
    {
      key: '4',
      label: 'Date Business Started',
      children: businessStartedAt
        ? dayjs(businessStartedAt).format(DEFAULT_DATE_FORMAT.format)
        : ''
    },
    {
      key: '5',
      label: 'Entity Type',
      children: BUSINESS_ENTITY_OPTIONS_MAP[businessEntityType ?? '']
    },
    {
      key: '6',
      label: 'State of Formation',
      children: USA_STATE_OPTIONS.find(
        (state) => state.label === formationState
      )?.label
    },
    {
      key: '7',
      label: 'Business EIN',
      children: businessEIN
    }
  ]

  return (
    <Descriptions
      className='review-step__descriptions'
      title='Business Information'
      column={1}
      extra={
        <Button
          type='text'
          onClick={() => {
            dispatch(toggleIsNavigatedFromReview())
            dispatch(setDirection('backward'))

            setTimeout(() => {
              dispatch(changeStep({ currentStep: BUSINESS_INFO_STEPS[0] }))
            }, 100)
          }}
        >
          <img src={editIcon} alt='edit-icon' />
        </Button>
      }
      colon={false}
      items={items}
    />
  )
}

export default BusinessInformationDescriptions
