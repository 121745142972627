import { PhoneNumber } from 'antd-phone-input'

const getFormattedPhoneNumber = (value: PhoneNumber | string): string => {
  if (typeof value === 'string') {
    return value
  }

  const { countryCode, phoneNumber } = value || {}
  return countryCode && phoneNumber ? `${countryCode}${phoneNumber}` : ''
}

export default getFormattedPhoneNumber
