import { FC, ReactElement, useEffect, useState } from 'react'

import { Button, Collapse, CollapseProps, Form, Space } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import classNames from 'classnames'

import { patchApplication } from '@/api/app.service'
import PlusIcon from '@/components/icons/PlusIcon'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { USA_STATE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import expandIcon from '@/images/icons/expand-icon.svg'
import { TFormOwnerField } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import AccordionItemLabel from './AccordionItemLabel'
import DeleteOwnerButton from './DeleteOwnerButton'
import StepFormFields from './StepFormFields'

import './BusinessInfoThirdStep.scss'

const { owners: ownersFieldName } = FORM_FIELD_NAMES_MAP

const BusinessInfoThirdStep: FC<IStepProps> = ({ form, next }) => {
  const [activeAccordionKey, setActiveAccordionKey] = useState(['0'])
  const owners = useWatch(ownersFieldName) || []
  const [isStepValid, setIsStepValid] = useState<boolean>(false)
  const prefilledOwnersCount = useTypedSelector(
    (state) => state.formState.prefilledData.owners.length
  )
  // const [isShowList, setIsShowList] = useState<boolean>(false)

  useEffect(() => {
    // const expectedOwnersList = []
    // for (let i = 0; i < prefilledOwnersCount; i++) {
    //   expectedOwnersList.push({ firstName: '', lastName: '' })
    // }
    // form.setFieldValue([ownersFieldName, 0], expectedOwnersList)
    // setIsShowList(true)
  }, [])

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsStepValid(true))
      .catch((err) => setIsStepValid(false))
  })

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ owners = [] }) => {
      await tryCatchWithMessage(async () => {
        const propsObj = {
          path: '/owners',
          value: owners.map((owner: TFormOwnerField, idx: number) => ({
            ...owner,
            phoneNumber: getFormattedPhoneNumber(owner.phoneNumber),
            isPrimary: idx === 0,
            address: {
              ...owner.address,
              usaStateType: USA_STATE_OPTIONS.find(
                (state) => state.label === owner.address?.usaStateType
              )?.value
            }
          }))
        }

        await patchApplication(applicationId, [propsObj])
      })
    }
  )

  const ownershipPercentage = owners.reduce(
    (acc: number, val?: { businessWeight?: number }) =>
      acc + (val?.businessWeight ?? 0),
    0
  )

  const isOwnershipValid = ownershipPercentage === 100
  const isMultipleOwners = prefilledOwnersCount > 1

  return (
    <div className={'business-info form-step'}>
      <h2 className={'business-info__title'}>Owner information</h2>
      <p className={'business-info__subtitle'}>
        Please provide a few details about the business owners
      </p>

      <Form.List
        name={ownersFieldName}
        // initialValue={[{ value: '' }, { value: '' }]}
      >
         {(fields, { add, remove }) => {
          const items: CollapseProps['items'] = fields.map(
            ({ key, name }, idx) => ({
              key,
              label: <AccordionItemLabel name={name} />,
              className: classNames('business-info__accordion-item', {
                'is-multiple-owners': isMultipleOwners
              }),
              children: (
                <Space
                  direction='vertical'
                  style={{ display: 'flex' }}
                  className={'ownership__fields'}
                >
                  <StepFormFields
                  name={name}
                  owners={owners}
                  ownershipPercentage={ownershipPercentage}
                  idx={idx}
                  form={form}
                />

                {idx > 0 && (
                  <DeleteOwnerButton
                    name={name}
                    onRemove={() => {
                      remove(name)
                      setActiveAccordionKey([(fields.length - 2).toString()])
                    }}
                  />
                )}
              </Space>
            )
          }))

          const addOwner = () => {
            add()
            setActiveAccordionKey([items.length.toString()])
          }

          return (
            <>
              <Collapse
                items={items}
                activeKey={activeAccordionKey}
                onChange={setActiveAccordionKey}
                accordion
                expandIcon={({ isActive }): ReactElement => (
                  <img
                    src={expandIcon}
                    alt='expand-icon'
                    style={{
                      transition: 'transform 0.3s ease',
                      transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                  />
                )}
                expandIconPosition='end'
              />

              <div className={'business-info__total-ownership-container'}>
                <h4
                  className={'business-info__total-ownership'}
                >{`Total: ${ownershipPercentage}% ownership`}</h4>

                {!isOwnershipValid && (
                  <p className={'business-info__total-ownership-warning'}>
                    Please ensure the total ownership equals 100%
                  </p>
                )}
              </div>

              {!!ownershipPercentage && !isOwnershipValid && (
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type='dashed'
                    onClick={addOwner}
                    block
                    color='primary'
                    style={{
                      background: 'var(--brand-secondary)'
                    }}
                    size='large'
                    className={'ownership__add-owner'}
                    icon={<PlusIcon disabled={isOwnershipValid} />}
                    disabled={isOwnershipValid}
                  >
                    Add additional owners
                  </Button>
                </Form.Item>
              )}
            </>
          )
        }}
      </Form.List>

      <Button
        loading={isLoading}
        type={'primary'}
        disabled={!isOwnershipValid || !isStepValid}
        onClick={() => {
          patch()
        }}
        className={'business-info__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default BusinessInfoThirdStep
