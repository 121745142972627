import { ReactElement } from 'react'

const CheckIcon = (): ReactElement => {
  return (
    <svg
      width='88'
      height='88'
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        opacity='0.18'
        cx='44'
        cy='44'
        r='44'
        fill='var(--brand-primary)'
      />
      <rect
        x='14'
        y='14'
        width='60'
        height='60'
        rx='30'
        fill='var(--brand-primary)'
      />
      <path
        d='M57.1663 35.959L39.5826 53.5427C38.9155 54.2098 37.8338 54.2098 37.1667 53.5427L29.833 46.209'
        stroke='#FCFCFC'
        strokeWidth='4'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default CheckIcon
