import { ReactElement, useState } from 'react'

import { Input, Modal, ModalProps } from 'antd'

import { ACCESS_TOKEN_KEY } from '@/constants/localStorage'

import './AccessTokenModal.scss'

type TApiKeyModalProps = ModalProps & {
  toggleModalOpen: VoidFunction
}

const AccessTokenModal = ({
  toggleModalOpen,
  open
}: TApiKeyModalProps): ReactElement => {
  const [accessToken, setAccessToken] = useState('')

  const toggleBlurredClass = () => {
    document.body.classList.toggle('blurred')
  }

  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      okText='Save'
      open={open}
      centered
      afterOpenChange={toggleBlurredClass}
      closeIcon={null}
      onOk={() => {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
        toggleModalOpen()
      }}
      okButtonProps={{
        disabled: accessToken.length < 3
      }}
      className='access-token-modal'
    >
      <h2 className='access-token-modal__title'>
        Please provide api key to continue
      </h2>
      <Input
        value={accessToken}
        onChange={(e) => setAccessToken(e.target.value)}
        className='access-token-modal__input'
      />
    </Modal>
  )
}

export default AccessTokenModal
