import { useEffect } from 'react'

import {
  BASIC_INFO_STEPS,
  BUSINESS_INFO_STEPS,
  PERSONAL_INFO_STEPS,
  REVIEW_STEP
} from '@/constants/formSteps'
import { setStep } from '@/redux/features/timeLineSlice'

import { useTypedDispatch, useTypedSelector } from './redux'

const useCurrentNavItem = () => {
  const currentStep = useTypedSelector(({ formState }) => formState.currentStep)
  const dispatch = useTypedDispatch()

  useEffect(() => {
    if (BASIC_INFO_STEPS.includes(currentStep)) {
      dispatch(setStep('basic-information'))
      return
    }
    if (PERSONAL_INFO_STEPS.includes(currentStep)) {
      dispatch(setStep('personal-information'))
      return
    }
    if (BUSINESS_INFO_STEPS.includes(currentStep)) {
      dispatch(setStep('business-information'))
      return
    }
    if (currentStep === REVIEW_STEP) {
      dispatch(setStep('review'))
    }
  }, [currentStep, dispatch])
}

export default useCurrentNavItem
