import { ReactElement, useState } from 'react'

import { Button, Modal } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'

type TDeleteOwnerButtonProps = {
  name: number
  onRemove: VoidFunction
}

const { firstName, lastName } = OWNER_INFO_FIELD_NAMES_MAP

const { owners } = FORM_FIELD_NAMES_MAP

const DeleteOwnerButton = ({
  onRemove,
  name
}: TDeleteOwnerButtonProps): ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  const firstNameValue = useWatch([owners, name, firstName])
  const lastNameValue = useWatch([owners, name, lastName])

  const fullName =
    firstNameValue && lastNameValue
      ? `${firstNameValue} ${lastNameValue}`
      : 'the owner'

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        onClick={toggleDeleteModal}
        style={{ height: 40 }}
        block
      >
        Delete this owner
      </Button>
      <Modal
        open={showDeleteModal}
        title={`Are you sure you want to delete ${fullName} from the list of additional owners?`}
        onCancel={toggleDeleteModal}
        onOk={() => {
          onRemove()
          toggleDeleteModal()
        }}
        closeIcon={null}
        cancelButtonProps={{
          style: { color: 'var(--brand-primary)' },
          size: 'large'
        }}
        okButtonProps={{
          size: 'large'
        }}
        okText='Delete'
        centered
      />
    </>
  )
}

export default DeleteOwnerButton
