import { ReactElement } from 'react'

import { Button } from 'antd'

import { useTypedDispatch } from '@/hooks/redux'
import { changeStep, setDirection } from '@/redux/features/formStateSlice'

const PartiallyApprovedView = (): ReactElement => {
  const dispatch = useTypedDispatch()

  return (
    <div className={'full-screen-block form-step'}>
      <h2 className={'owner-info__title'}>You are almost funded!</h2>
      <p className={'owner-info__subtitle partially-approved'}>
        Please answer a few more questions, we’ll be able to provide you with
        the best available options tailored for you
      </p>
      <Button
        type={'primary'}
        onClick={() => {
          dispatch(setDirection('forward'))

          setTimeout(() => {
            dispatch(changeStep({ direction: 'forward' }))
          }, 100)
        }}
        className={'owner-info__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default PartiallyApprovedView
