import {
  AddressDto,
  ApplicationDto,
  BusinessOverviewDto,
  OwnerDto,
  TargetBusinessOverviewDto
} from '@/generated-types'

type TOwnerKey = keyof Omit<OwnerDto, 'id'>
type TFormKey =
  | keyof Omit<ApplicationDto, 'id' | 'isDraft'>
  | 'businessTaxReturnsAccess'
  | 'personalTaxReturnsAccess'
  | 'driverLicensesAccess'
type TTargetBusinessOverviewKey = keyof Omit<TargetBusinessOverviewDto, 'id'>
type TAddressOverviewKey = keyof Omit<AddressDto, 'id'>
type TBusinessOverviewKey = keyof Omit<BusinessOverviewDto, 'id'>

const FORM_FIELD_NAMES_MAP: Record<TFormKey, TFormKey> = {
  annualProfitType: 'annualProfitType',
  annualRevenueType: 'annualRevenueType',
  ficoScoreType: 'ficoScoreType',
  timeframeType: 'timeframeType',
  totalFunding: 'totalFunding',
  fundingPurposeType: 'fundingPurposeType',
  fundingPurpose: 'fundingPurpose',
  targetBusinessOverview: 'targetBusinessOverview',
  owners: 'owners',
  businessOverview: 'businessOverview',
  businessTaxReturns: 'businessTaxReturns',
  personalTaxReturns: 'personalTaxReturns',
  driverLicences: 'driverLicences',
  businessTaxReturnsAccess: 'businessTaxReturnsAccess',
  personalTaxReturnsAccess: 'personalTaxReturnsAccess',
  driverLicensesAccess: 'driverLicensesAccess',
  annualRevenue: 'annualRevenue',
  annualProfit: 'annualProfit'
}

const OWNER_INFO_FIELD_NAMES_MAP: Record<TOwnerKey, TOwnerKey> = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  birthDate: 'birthDate',
  businessWeight: 'businessWeight',
  isPrimary: 'isPrimary',
  address: 'address',
  socialSecurityNumber: 'socialSecurityNumber'
}

const TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP: Record<
  TTargetBusinessOverviewKey,
  TTargetBusinessOverviewKey
> = {
  name: 'name',
  industryType: 'industryType',
  usaStateType: 'usaStateType',
  businessStartedAt: 'businessStartedAt',
  isStarted: 'isStarted',
  isFranchise: 'isFranchise',
  hasGuarantors: 'hasGuarantors'
}

const BUSINESS_OVERVIEW_FIELD_NAMES_MAP: Record<
  TBusinessOverviewKey,
  TBusinessOverviewKey
> = {
  name: 'name',
  isDBA: 'isDBA',
  dbaName: 'dbaName',
  phoneNumber: 'phoneNumber',
  address: 'address',
  businessStartedAt: 'businessStartedAt',
  businessEntityType: 'businessEntityType',
  formationState: 'formationState',
  businessEIN: 'businessEIN'
}

const ADDRESS_FIELD_NAMES_MAP: Record<
  TAddressOverviewKey,
  TAddressOverviewKey
> = {
  street: 'street',
  city: 'city',
  zipCode: 'zipCode',
  usaStateType: 'usaStateType'
}

export {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  ADDRESS_FIELD_NAMES_MAP
}
