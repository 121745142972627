import { ReactElement } from 'react'

import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select
} from 'antd'
import dayjs from 'dayjs'

import FormPhoneInput from '@/components/form/form-phone-input/FormPhoneInput'
import SSNFormItem from '@/components/steps/business-info-third-step/SSNFormItem'
import DEFAULT_DATE_FORMAT, { DEFAULT_DATE_FORMAT_PLACEHOLDER } from '@/constants/defaultDateFormat'
import {
  ADDRESS_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { USA_STATE_OPTIONS } from '@/constants/formFieldOptions'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import calendarIcon from '@/images/icons/calendar-icon.svg'
import locationIcon from '@/images/icons/location-icon.svg'
import mailIcon from '@/images/icons/mail-icon.svg'
import percentIcon from '@/images/icons/percentage-icon.svg'

type TStepFormFieldsProps = {
  name: number
  owners: { businessWeight?: number }[]
  ownershipPercentage: number
  idx: number
  form: FormInstance
}

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  birthDate,
  businessWeight,
  address
} = OWNER_INFO_FIELD_NAMES_MAP

const { city, zipCode, usaStateType, street } = ADDRESS_FIELD_NAMES_MAP

const StepFormFields = ({
  name,
  owners,
  ownershipPercentage,
  idx,
  form
}: TStepFormFieldsProps): ReactElement => {
  const isPrimaryOwner = idx === 0

  useSetPrefilledData(
    form,
    [
      firstName,
      lastName,
      email,
      birthDate,
      businessWeight,
      address,
      'socialSecurityNumber'
    ],
    'owners',
    idx
  )

  return (
    <>
      <Form.Item
        name={[name, firstName]}
        label='First Name'
        rules={[
          {
            required: true,
            message: 'The First Name is required',
            whitespace: true
          }
        ]}
        className={'ownership__input'}
        style={{ width: '100%' }}
      >
        <Input placeholder='First Name' />
      </Form.Item>
      <Form.Item
        name={[name, lastName]}
        label='Last Name'
        rules={[
          {
            required: true,
            message: 'The Last Name is required',
            whitespace: true
          }
        ]}
        className={'ownership__input'}
        style={{ width: '100%' }}
      >
        <Input placeholder='Last Name' />
      </Form.Item>
      <Form.Item
        name={[name, businessWeight]}
        label='Ownership Percentage'
        rules={[
          {
            required: true,
            type: 'number',
            message: 'Total ownership percentage should be 100%',
            min: 1,
            max: getMaxOwnershipPercentageValidation(
              ownershipPercentage,
              owners[name]?.businessWeight
            )
          }
        ]}
        className={'ownership__input'}
        style={{ width: '100%' }}
      >
        <InputNumber
          type='number'
          min={1}
          max={100}
          placeholder='Enter Percentage'
          suffix={
            <img src={percentIcon} alt={'Percent'} width={18} height={18} />
          }
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        name={[name, email]}
        label='Email'
        className={'ownership__input-with-prefix'}
        rules={[
          {
            required: true,
            type: 'email',
            message: 'The email should include “@” and “.”'
          }
        ]}
        style={{ width: '100%' }}
      >
        <Input
          placeholder='name@email.com'
          type='email'
          prefix={<img src={mailIcon} alt={'mail-icon'} />}
        />
      </Form.Item>

      <FormPhoneInput
        fieldName={[name, phoneNumber]}
        className={'ownership__input-with-prefix ownership__field-phone'}
        required={isPrimaryOwner}
      />

      <Form.Item
        name={[name, birthDate]}
        label='Date of Birth'
        rules={[
          {
            required: isPrimaryOwner,
            message: 'Please enter a Date'
          }
        ]}
        className={'ownership__date-wrapper ownership__field-date'}
      >
        <DatePicker
          style={{ display: 'flex' }}
          autoComplete="off"
          format={DEFAULT_DATE_FORMAT}
          placeholder={DEFAULT_DATE_FORMAT_PLACEHOLDER}
          suffixIcon={<img src={calendarIcon} alt={'input-icon'} />}
          className={'ownership__date'}
          disabledDate={(currentDate) => {
            return currentDate && currentDate > dayjs().endOf('day')
          }}
        />
      </Form.Item>

      <Form.Item
        name={[name, address, street]}
        label='Address'
        rules={[
          {
            required: isPrimaryOwner,
            message: 'Please enter an Address',
            whitespace: true
          }
        ]}
        className={'ownership__input-with-prefix'}
        style={{ width: '100%' }}
      >
        <Input
          placeholder='Enter the Address'
          prefix={<img src={locationIcon} alt={'Location icon'} />}
        />
      </Form.Item>

      <Form.Item
        name={[name, address, city]}
        label='City'
        className={'ownership__input ownership__field-city'}
        rules={[
          {
            required: isPrimaryOwner,
            message: 'Please enter a City',
            whitespace: true
          }
        ]}
      >
        <Input placeholder='Enter Address' />
      </Form.Item>
      <Form.Item
        name={[name, address, usaStateType]}
        label='State'
        className={'ownership__select ownership__field-state'}
        rules={[
          {
            required: isPrimaryOwner,
            message: 'Please select a State'
          }
        ]}
      >
        <Select
          placeholder='Select from the list'
          showSearch
          popupClassName={'select-dropdown'}
        >
          {USA_STATE_OPTIONS.map(({ label, value }) => (
            <Select.Option key={value} value={label}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={[name, address, zipCode]}
        label='Zip Code'
        className={'ownership__input ownership__field-zip'}
        rules={[
          {
            required: isPrimaryOwner,
            message: 'Please enter a Zip Code',
            whitespace: true
          }
        ]}
      >
        <Input placeholder='Enter Zip Code' />
      </Form.Item>

      <SSNFormItem name={name} isPrimaryOwner={isPrimaryOwner} />
    </>
  )
}

const getMaxOwnershipPercentageValidation = (
  totalPercentage: number,
  currentPercentage = 0
) => 100 - (totalPercentage - currentPercentage)

export default StepFormFields
