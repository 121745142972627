import { FC, useEffect, useState } from 'react'

import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import dayjs from 'dayjs'

import { patchApplication } from '@/api/app.service'
import FormPhoneInput from '@/components/form/form-phone-input/FormPhoneInput'
import DEFAULT_DATE_FORMAT, { DEFAULT_DATE_FORMAT_PLACEHOLDER } from '@/constants/defaultDateFormat'
import {
  ADDRESS_FIELD_NAMES_MAP,
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { USA_STATE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import calendarIcon from '@/images/icons/calendar-icon.svg'
import locationIcon from '@/images/icons/location-icon.svg'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessInfoFirstStep.scss'

const { businessOverview } = FORM_FIELD_NAMES_MAP
const { isDBA, address, name, dbaName, phoneNumber, businessStartedAt } =
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP
const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const BusinessInfoFirstStep: FC<IStepProps> = ({ form, next }) => {
  const businessNameValue = useWatch([businessOverview, name], form)
  const businessAsDbaValue = useWatch([businessOverview, isDBA], form)
  const nameDbaValue = useWatch([businessOverview, dbaName], form)
  const addressValue = useWatch([businessOverview, address], form)
  const phoneValue = useWatch([businessOverview, phoneNumber], form)
  const dateStartedValue = useWatch([businessOverview, businessStartedAt], form)
  const [isStepValid, setIsStepValid] = useState(false)

  useSetPrefilledData(
    form,
    [name, isDBA, dbaName, address, businessStartedAt],
    'businessOverview'
  )

  const validateStep = () => {
    if (businessAsDbaValue) {
      if (
        businessNameValue?.length &&
        nameDbaValue?.length &&
        addressValue?.street?.length &&
        addressValue?.city?.length &&
        addressValue?.usaStateType &&
        addressValue?.zipCode?.length &&
        phoneValue?.valid() &&
        dateStartedValue
      ) {
        setIsStepValid(true)
      } else {
        setIsStepValid(false)
      }
    } else {
      if (
        businessNameValue?.length &&
        addressValue?.street?.length &&
        addressValue?.city?.length &&
        addressValue?.usaStateType &&
        addressValue?.zipCode?.length &&
        phoneValue?.valid() &&
        dateStartedValue
      ) {
        setIsStepValid(true)
      } else {
        setIsStepValid(false)
      }
    }
  }
  useEffect(() => {
    validateStep()
  })

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(next, async () => {
    const businessOverviewValue = form.getFieldValue(businessOverview)

    const formattedBusinessOverviewValue = {
      ...businessOverviewValue,
      address: {
        ...businessOverviewValue.address,
        usaStateType: USA_STATE_OPTIONS.find(
          (state) => state.label === businessOverviewValue.address.usaStateType
        )?.value
      }
    }

    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/businessOverview',
          value: {
            ...formattedBusinessOverviewValue,
            phoneNumber: getFormattedPhoneNumber(
              businessOverviewValue.phoneNumber
            )
          }
        }
      ])
    })
  })

  return (
    <div className={'business-info form-step'}>
      <h2 className={'business-info__title'}>Business information</h2>
      <p className={'business-info__subtitle'}>
        Please provide a few details about the business
      </p>
      <div className={'business-info__fields'}>
        <Form.Item
          name={[businessOverview, name]}
          label='Business Name'
          className={'business-info__input'}
          rules={[
            {
              required: true,
              message: 'Please enter a Business Name',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='Enter Business Name' />
        </Form.Item>
        <Form.Item
          valuePropName='checked'
          name={[businessOverview, isDBA]}
          className={'business-info__checkbox'}
        >
          <Checkbox>Are you doing business as a DBA?</Checkbox>
        </Form.Item>
        {businessAsDbaValue && (
          <Form.Item
            name={[businessOverview, dbaName]}
            label='Name You Doing Business As'
            className={'business-info__input'}
            rules={[
              {
                required: true,
                message: 'Please enter a Business Name',
                whitespace: true
              }
            ]}
          >
            <Input placeholder='Enter Business Name' />
          </Form.Item>
        )}
        <Form.Item
          name={[businessOverview, address, street]}
          label='Address'
          className={'business-info__input-with-prefix'}
          rules={[
            {
              required: true,
              message: 'Please enter an Address',
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder='Enter Address'
            prefix={<img src={locationIcon} alt={'location-icon'} />}
          />
        </Form.Item>
        <Form.Item
          name={[businessOverview, address, city]}
          label='City'
          className={'business-info__input business-info__field-city'}
          rules={[
            {
              required: true,
              message: 'Please enter a City',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='Enter Address' />
        </Form.Item>
        <Form.Item
          name={[businessOverview, address, usaStateType]}
          label='State'
          className={'business-info__select business-info__field-state'}
          rules={[
            {
              required: true,
              message: 'Please select a State'
            }
          ]}
        >
          <Select
            placeholder='Select State'
            showSearch
            popupClassName={'select-dropdown'}
          >
            {USA_STATE_OPTIONS.map(({ label, value }) => (
              <Select.Option key={value} value={label}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={[businessOverview, address, zipCode]}
          label='Zip Code'
          className={'business-info__input business-info__field-zip'}
          rules={[
            {
              required: true,
              message: 'Please enter a Zip Code',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='Enter Zip Code' />
        </Form.Item>

        <FormPhoneInput
          fieldName={[businessOverview, phoneNumber]}
          label='Business Phone Number'
          className={
            'business-info__input-with-prefix business-info__field-phone'
          }
        />
        <Form.Item
          name={[businessOverview, businessStartedAt]}
          label='Date Business Started'
          rules={[
            {
              required: true,
              message: 'Please enter a Date'
            }
          ]}
          className={'business-info__date-wrapper business-info__field-date'}
        >
          <DatePicker
            style={{ display: 'flex' }}
            autoComplete="off"
            format={DEFAULT_DATE_FORMAT}
            placeholder={DEFAULT_DATE_FORMAT_PLACEHOLDER}
            suffixIcon={<img src={calendarIcon} alt={'input-icon'} />}
            disabledDate={(currentDate) => {
              return currentDate && currentDate > dayjs().endOf('day')
            }}
            className={'business-info__date'}
          />
        </Form.Item>

        <Button
          type={'primary'}
          onClick={() => {
            patch()
          }}
          loading={isLoading}
          className={'business-info__next'}
          disabled={!isStepValid}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default BusinessInfoFirstStep
