import { ReactElement } from 'react'

import { Flex } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { OWNER_INFO_FIELD_NAMES_MAP } from '@/constants/formFieldNames'

type AccordionItemLabelProps = {
  name: number
}

const { firstName, lastName, businessWeight } = OWNER_INFO_FIELD_NAMES_MAP

const AccordionItemLabel = ({
  name
}: AccordionItemLabelProps): ReactElement => {
  const firstNameValue = useWatch(['owners', name, firstName])
  const lastNameValue = useWatch(['owners', name, lastName])
  const businessWeightValue = useWatch(['owners', name, businessWeight])

  const fullName =
    firstNameValue && lastNameValue
      ? `${firstNameValue} ${lastNameValue}`
      : 'Owner'

  return (
    <Flex gap={8}>
      <p>{fullName}</p>
      <div style={{ marginLeft: 'auto', flex: '0 0 auto' }}>
        <p>{businessWeightValue ?? 0}% ownership</p>
      </div>
    </Flex>
  )
}

export default AccordionItemLabel
