import { ReactElement } from 'react'

import { Modal, ModalProps, Typography } from 'antd'

const PrivacyModal = (props: ModalProps): ReactElement => {
  return (
    <Modal
      width='100%'
      title={<h3 className='modal__title'>Privacy Policy</h3>}
      {...props}
      className='modal'
    >
      <div
        className='with-custom-scrollbar'
        style={{ maxHeight: 'calc(100vh - 150px)' }}
      >
        <Typography.Paragraph>
          At LoanBud Inc, we take privacy very seriously and are committed to
          protecting the personal information of our users. This Privacy Policy
          explains how we collect, use, and disclose personal information when
          you use our website and services.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Collection of Information: We collect personal information such as
          your name, address, phone number, email address, and financial
          information, including credit score and income level, when you apply
          for a loan through our website. We may also collect information about
          your computer or mobile device, including IP address and browser type.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Use of Information: We use your personal information to process and
          approve your loan application, communicate with you regarding your
          loan, and provide customer service. We may also use your personal
          information for marketing purposes and to improve our services.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Disclosure of Information: We may disclose your personal information
          to third-party service providers who assist us with processing your
          loan application and providing customer service. We may also disclose
          your personal information to government agencies, law enforcement, or
          other third parties when required by law or to protect our legal
          rights. No mobile information will be shared with third
          parties/affiliates for marketing/promotional purposes.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Security: We take reasonable steps to protect your personal
          information from unauthorized access, disclosure, or alteration.
          However, no security measures are foolproof, and we cannot guarantee
          the security of your personal information.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Cookies: We use cookies to collect information about your use of our
          website and services. Cookies are small files that are stored on your
          computer or mobile device. You can disable cookies in your browser
          settings, but this may affect the functionality of our website.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Third-Party Links: Our website may contain links to third-party
          websites. We are not responsible for the privacy practices or content
          of these websites.
        </Typography.Paragraph>
        <Typography.Paragraph>
          SMS Communication: By submitting a form, you are opting in to receive
          transactional SMS messages from LoanBud. Reply STOP to unsubscribe.
          Reply HELP for help. Consent is not a condition of purchase. Message
          frequency varies. Message & data rates may apply.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Changes to this Privacy Policy: We may update this Privacy Policy from
          time to time. Any changes will be posted on our website, and your
          continued use of our website and services after the changes have been
          posted will constitute your acceptance of the revised Privacy Policy.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Contact Us: If you have any questions or concerns about this Privacy
          Policy or our use of your personal information, please contact us at{' '}
          <a href='mailto:social@loanbud.com' target='_blank' rel='noreferrer'>
            social@loanbud.com
          </a>
          .
        </Typography.Paragraph>
      </div>
    </Modal>
  )
}

export default PrivacyModal
