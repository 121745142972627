import { ReactElement } from 'react'

import { Form } from 'antd'
import PhoneInput from 'antd-phone-input'
import { FormItemProps } from 'antd/es/form'

type TFormPhoneInputProps = FormItemProps & {
  fieldName: (string | number)[]
  required?: boolean
  label?: string
}

const FormPhoneInput = ({
  fieldName,
  className,
  required = true,
  label = 'Phone Number'
}: TFormPhoneInputProps): ReactElement => {
  return (
    <Form.Item
      name={fieldName}
      className={className}
      label={label}
      rules={[
        {
          required,
          validator: (_, { valid }) => {
            if (valid() || !required) return Promise.resolve()
            return Promise.reject(new Error('Invalid Phone Number'))
          }
        }
      ]}
    >
      <PhoneInput enableArrow enableSearch className='phone-input' />
    </Form.Item>
  )
}

export default FormPhoneInput
