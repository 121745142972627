import { FC } from 'react'

import { Button, Form, Input, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import {
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import {
  BUSINESS_ENTITY_OPTIONS,
  USA_STATE_OPTIONS
} from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessInfoSecondStep.scss'

const { businessOverview } = FORM_FIELD_NAMES_MAP
const { formationState, businessEIN, businessEntityType } =
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const BusinessInfoSecondStep: FC<IStepProps> = ({ next, form }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )
  const formationStateValue = useWatch([businessOverview, formationState], form)
  const businessEntityValue = useWatch(
    [businessOverview, businessEntityType],
    form
  )
  const businessEinValue = useWatch([businessOverview, businessEIN], form)

  useSetPrefilledData(
    form,
    [formationState, businessEntityType, businessEIN],
    'businessOverview'
  )

  const [patch, isLoading] = usePatchApplication(next, async () => {
    const businessOverviewValue = form.getFieldValue(businessOverview)

    const formattedBusinessOverviewValue = {
      ...businessOverviewValue,
      address: {
        ...businessOverviewValue.address,
        usaStateType: USA_STATE_OPTIONS.find(
          (state) => state.label === businessOverviewValue.address.usaStateType
        )?.value
      },
      formationState: USA_STATE_OPTIONS.find(
        (state) => state.label === businessOverviewValue.address.usaStateType
      )?.value
    }

    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/businessOverview',
          value: {
            ...formattedBusinessOverviewValue,
            phoneNumber: getFormattedPhoneNumber(
              businessOverviewValue.phoneNumber
            )
          }
        }
      ])
    })
  })

  return (
    <div className={'business-info form-step'}>
      <h2 className={'business-info__title'}>Business information</h2>
      <p className={'business-info__subtitle'}>
        Please provide a few details about the business
      </p>
      <div className={'business-info__fields'}>
        <Form.Item
          name={[businessOverview, businessEntityType]}
          className={'business-info__select business-info__field-entity-type'}
          label='Entity Type'
          rules={[
            {
              required: true,
              message: 'Please select a Business Entity'
            }
          ]}
        >
          <Select placeholder='Select from the list'>
            {BUSINESS_ENTITY_OPTIONS.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={[businessOverview, formationState]}
          label='State of Formation'
          className={
            'business-info__select business-info__field-state-of-formation'
          }
          rules={[
            {
              required: true,
              message: 'Please select a State'
            }
          ]}
        >
          <Select
            placeholder='Select from the list'
            showSearch
            popupClassName={'select-dropdown'}
          >
            {USA_STATE_OPTIONS.map(({ label, value }) => (
              <Select.Option key={value} value={label}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={[businessOverview, businessEIN]}
          label='Business EIN'
          className={'business-info__input business-info__field-ein'}
          rules={[
            {
              required: true,
              pattern: /^\d{2}-\d{7}$/,
              len: 10,
              message: 'Invalid EIN. Please check and try again'
            }
          ]}
        >
          <Input
            maxLength={10}
            placeholder='Enter EIN'
            onChange={({ target: { value } }) => {
              const rawValue = value.replace(/-/g, '')
              const formattedValue = rawValue.replace(/(\d{2})(\d+)/, '$1-$2')

              form.setFieldValue(
                [businessOverview, businessEIN],
                formattedValue
              )
            }}
          />
        </Form.Item>
        <Button
          type={'primary'}
          loading={isLoading}
          onClick={() => {
            patch()
          }}
          disabled={
            !(
              businessEntityValue &&
              formationStateValue &&
              /^\d{2}-\d{7}$/.test(businessEinValue)
            )
          }
          className={'business-info__next'}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default BusinessInfoSecondStep
