import { Form } from 'antd'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import TimeLine from '@/components/time-line/TimeLine'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import useCurrentNavItem from '@/hooks/useCurrentNavItem'
import useFormSteps from '@/hooks/useFormSteps'
import useHiddenSteps from '@/hooks/useHiddenSteps'
import { changeStep, setDirection } from '@/redux/features/formStateSlice'

import BackToReviewButton from '../back-to-review-button/BackToReviewButton'

import './MultiStepForm.scss'

const MultiStepForm = () => {
  const [form] = Form.useForm()
  const { currentStep, direction } = useTypedSelector(
    ({ formState }) => formState
  )

  const dispatch = useTypedDispatch()

  useCurrentNavItem()
  useHiddenSteps(form)

  const next = async (
    apiCallback: <T>(data: T) => Promise<void>,
    step?: number
  ): Promise<void> => {
    dispatch(setDirection('forward'))

    const onStepChange = () => {
      setTimeout(() => {
        window?.scroll({ top: 0, behavior: 'smooth' })
        dispatch(changeStep({ currentStep: step, direction: 'forward' }))
      }, 100)
    }

    await form
      .validateFields()
      .then(async (data) => {
        await apiCallback(data)
      })
      .then(onStepChange)
      .catch((e) => {
        if (!e?.errorFields?.length) {
          onStepChange()
        }

        // throw new Error(e?.response?.data?.message || e.message || 'Error!')
      })
  }

  const steps = useFormSteps({ form, next })

  const prev = () => {
    dispatch(setDirection('backward'))
    setTimeout(() => {
      dispatch(changeStep({ direction: 'backward' }))
    }, 100)
  }

  return (
    <div
      style={{ fontFamily: `'Poppins', sans-serif` }}
      className={'multi-step-form'}
    >
      <TimeLine prev={prev} form={form} />

      <TransitionGroup>
        <CSSTransition
          key={currentStep}
          timeout={500}
          classNames={
            direction === 'forward' ? 'fade-forward' : 'fade-backward'
          }
        >
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            className={'form-container'}
          >
            <div style={{ position: 'relative' }}>
              <Form
                preserve
                layout='vertical'
                form={form}
                initialValues={{
                  // NOTE: this field is required for the form to work correctly (MG)
                  owners: []
                }}
              >
                {steps[currentStep].content}
              </Form>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>

      <BackToReviewButton />
    </div>
  )
}

export default MultiStepForm
