import { useEffect } from 'react'

import { FormInstance } from 'antd'
import dayjs from 'dayjs'

import { USA_STATE_OPTIONS_MAP } from '@/constants/formFieldOptions'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import { setPrefilledData } from '@/redux/features/formStateSlice'

type TSetPrefilledDataHookProps = (
  form: FormInstance,
  fieldNames: string[],
  rootField?: string,
  ownerIndex?: number
) => number

const useSetPrefilledData: TSetPrefilledDataHookProps = (
  form,
  fieldNames,
  rootField = '',
  ownerIndex = 0
) => {
  const dispatch = useTypedDispatch()
  const prefilledData = useTypedSelector(
    ({ formState }) => formState.prefilledData
  )

  useEffect(() => {
    const newPrefilledState = structuredClone(prefilledData)

    if (!rootField) {
      fieldNames.map((fieldName) => {
        if (prefilledData[fieldName]) {
          if (fieldName === 'annualRevenue' || fieldName === 'annualProfit') {
            form.setFieldValue(
              fieldName,
              prefilledData[fieldName]
                .toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            )
          } else {
            form.setFieldValue(fieldName, prefilledData[fieldName])
          }
          newPrefilledState[fieldName] = null
        }
      })
    } else {
      // TARGET BUSINESS OVERVIEW
      if (rootField === 'targetBusinessOverview') {
        fieldNames.map((fieldName) => {
          if (prefilledData[rootField] && prefilledData[rootField][fieldName]) {
            if (fieldName === 'usaStateType') {
              form.setFieldValue(
                fieldName,
                USA_STATE_OPTIONS_MAP[prefilledData[rootField][fieldName]]
              )
            } else if (fieldName === 'businessStartedAt') {
              form.setFieldValue(
                fieldName,
                dayjs(prefilledData[rootField][fieldName])
              )
            } else {
              form.setFieldValue(fieldName, prefilledData[rootField][fieldName])
            }
            newPrefilledState[rootField][fieldName] = null
          }
        })
      }

      // OWNERS
      if (rootField === 'owners') {
        if (prefilledData[rootField] && prefilledData[rootField][ownerIndex]) {
          fieldNames.map((fieldName) => {
            form.setFieldValue('birthDate', dayjs('1977-08-31'))
            if (prefilledData[rootField][ownerIndex][fieldName]) {
              if (fieldName === 'birthDate') {
                form.setFieldValue(
                  [rootField, ownerIndex, fieldName],
                  dayjs(prefilledData[rootField][ownerIndex][fieldName])
                )
              } else if (fieldName === 'address') {
                form.setFieldValue([rootField, ownerIndex, fieldName], {
                  street: prefilledData[rootField][ownerIndex].address.street,
                  city: prefilledData[rootField][ownerIndex].address.city,
                  zipCode: prefilledData[rootField][ownerIndex].address.zipCode,
                  usaStateType:
                    USA_STATE_OPTIONS_MAP[
                      prefilledData[rootField][ownerIndex].address.usaStateType
                    ]
                })
              } else {
                form.setFieldValue(
                  [rootField, ownerIndex, fieldName],
                  prefilledData[rootField][ownerIndex][fieldName]
                )
              }
              newPrefilledState[rootField][ownerIndex][fieldName] = null
            }
          })
        }
      }
    }

    // BUSINESS OVERVIEW STEP 1
    if (rootField === 'businessOverview') {
      fieldNames.map((fieldName) => {
        if (prefilledData[rootField] && prefilledData[rootField][fieldName]) {
          if (fieldName === 'address') {
            form.setFieldValue([rootField, fieldName], {
              street: prefilledData[rootField].address.street,
              city: prefilledData[rootField].address.city,
              zipCode: prefilledData[rootField].address.zipCode,
              usaStateType:
                USA_STATE_OPTIONS_MAP[
                  prefilledData[rootField].address.usaStateType
                ]
            })
          } else if (fieldName === 'businessStartedAt') {
            form.setFieldValue(
              [rootField, fieldName],
              dayjs(prefilledData[rootField][fieldName])
            )
          } else if (fieldName === 'formationState') {
            form.setFieldValue(
              [rootField, fieldName],
              USA_STATE_OPTIONS_MAP[prefilledData[rootField][fieldName]]
            )
          } else {
            form.setFieldValue(
              [rootField, fieldName],
              prefilledData[rootField][fieldName]
            )
          }
          newPrefilledState[rootField][fieldName] = null
        }
      })
    }

    // dispatch(setPrefilledData(newPrefilledState))
  }, [])

  return 1
}

export default useSetPrefilledData
