import React from 'react'

import { Button, Form, Radio } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { TIMEFRAME_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './AccessTime.scss'

const { timeframeType } = FORM_FIELD_NAMES_MAP

const AccessTime: React.FC<IStepProps> = ({ form, next }) => {
  const accessTime = useWatch(timeframeType, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ timeframeType }: { timeframeType: string }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/timeframeType',
            value: timeframeType
          }
        ])
      })
    }
  )

  return (
    <div className={'access-time form-step'}>
      <h2 className={'access-time__title'}>
        How quickly do you need access to funds?
      </h2>
      <p className={'access-time__subtitle'}>
        Choose one of the options provided
      </p>
      <Form.Item
        name={timeframeType}
        rules={[{ required: true }]}
        className={'access-time__radio-group'}
      >
        <Radio.Group>
          {TIMEFRAME_TYPE_OPTIONS.map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Button
        type={'primary'}
        loading={isLoading}
        onClick={() => {
          patch()
        }}
        className={'access-time__next'}
        disabled={!accessTime}
      >
        Next
      </Button>
    </div>
  )
}

export default AccessTime
