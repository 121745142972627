import { ApplicationDto, PatchRequestDto } from '@/generated-types'

import APIinstance from './config'
import { GenericAbortSignal } from 'axios'

export const submitApplication = async (
  input: ApplicationDto
): Promise<ApplicationDto> => {
  const { data } = await APIinstance.post<ApplicationDto>('applications', input)
  return data
}

export const patchApplication = async <T>(
  applicationId: string,
  input: (Omit<PatchRequestDto, 'op' | 'value'> & {
    value: T
  })[]
): Promise<ApplicationDto> => {
  const inputArr = []

  for (const { value, path } of input) {
    inputArr.push({
      path,
      value,
      op: 'replace'
    })
  }

  const { data } = await APIinstance.patch<ApplicationDto>(
    `applications/${applicationId}`,
    JSON.stringify(inputArr)
  )

  return data
}

export const requestAutofill = async (
  applicationId: string,
  signal: GenericAbortSignal  
): Promise<ApplicationDto> => {
  const { data } = await APIinstance.post<ApplicationDto>(
    `applications/${applicationId}/autofill`,
    {},
    {
      timeout: 0,
      signal
    }
  )

  return data
}
