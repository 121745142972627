import { CloudFileDto } from '@/generated-types'
import { TFormFile } from '@/utils/formFileList'

import APIinstance from './config'

export const uploadFiles = async (
  files: TFormFile[]
): Promise<CloudFileDto[]> => {
  const formData = new FormData()

  files.forEach((file) => {
    file.originFileObj && formData.append('files', file.originFileObj)
  })

  const { data } = await APIinstance.post<CloudFileDto[]>(
    'cloud-files',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  )

  return data
}

export const deleteFile = async (fileId: string): Promise<void> => {
  await APIinstance.delete(`cloud-files/${fileId}`)
}
