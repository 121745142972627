import axios from 'axios'

import { ACCESS_TOKEN_KEY } from '@/constants/localStorage'

const API_PREFIX = '/api/v1/'

const API_HOST = 'https://dev.api.loanbud.io' + API_PREFIX

const APIinstance = axios.create({
  baseURL: `${API_HOST}`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

APIinstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken
    }

    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

export default APIinstance
