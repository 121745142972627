import { FormInstance } from 'antd'

import { TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP } from '@/constants/formFieldNames'

const {
  isStarted,
  isFranchise: isFranchiseFieldName,
  hasGuarantors: hasGuarantorsFieldName
} = TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const useShouldShowRejectedView = (form: FormInstance) => {
  const isBusinessStarted = form.getFieldValue(isStarted)
  const hasGuarantors = form.getFieldValue(hasGuarantorsFieldName)
  const isFranchise = form.getFieldValue(isFranchiseFieldName)

  return (
    isBusinessStarted === false &&
    hasGuarantors === false &&
    isFranchise === false
  )
}

export default useShouldShowRejectedView
