import { ReactElement } from 'react'

import { Flex, Typography } from 'antd'

import StarCheckIcon from '@/components/icons/StarCheckIcon'

const CongratulationsBlock = (): ReactElement => {
  return (
    <div className='congratulations-block'>
      <Flex
        align='center'
        justify='center'
        gap={8}
        className='congratulations-block__title-block'
      >
        <StarCheckIcon />
        <Typography.Title level={4} className='congratulations-block__title'>
          Congratulations!
        </Typography.Title>
      </Flex>
      <Typography.Text className='congratulations-block__subtitle'>
        Based on the information you’ve shared. You may be in a great position
        to take advantage of one or more of our offers. To proceed further,
        please provide some additional information about the business owners
      </Typography.Text>
    </div>
  )
}

export default CongratulationsBlock
