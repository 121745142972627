import { ReactElement } from 'react'

const StepMobileCompleted = (): ReactElement => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='22' height='22' rx='11' fill='var(--brand-primary)' />
      <path
        d='M16.3346 7.6665L9.47271 14.5284C9.21236 14.7888 8.79025 14.7888 8.5299 14.5284L5.66797 11.6665'
        stroke='#F1F1F1'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default StepMobileCompleted
