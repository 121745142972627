import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import { getPartnerConfig, partnersQueries } from '@/api/partners.service'
import { ACCESS_TOKEN_KEY } from '@/constants/localStorage'

const useInitializeApp = (toggleAccessTokenModal: VoidFunction): boolean => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { isLoading } = useQuery({
    queryKey: partnersQueries.partner.me.queryKey,
    queryFn: getPartnerConfig
  })

  useEffect(() => {
    const accessTokenParam = searchParams.get(ACCESS_TOKEN_KEY)
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (!accessToken && !accessTokenParam) {
      toggleAccessTokenModal()

      return
    }

    if (accessTokenParam) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessTokenParam)
      searchParams.delete(ACCESS_TOKEN_KEY)

      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setSearchParams, toggleAccessTokenModal])

  return isLoading
}

export default useInitializeApp
