import { FC, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import dayjs from 'dayjs'

import { patchApplication } from '@/api/app.service'
import { partnersQueries } from '@/api/partners.service'
import FormPhoneInput from '@/components/form/form-phone-input/FormPhoneInput'
import DEFAULT_DATE_FORMAT, { DEFAULT_DATE_FORMAT_PLACEHOLDER } from '@/constants/defaultDateFormat'
import {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { PartnerDto } from '@/generated-types'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import calendarIcon from '@/images/icons/calendar-icon.svg'
import mailIcon from '@/images/icons/mail-icon.svg'
import { TFormOwnerField } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import CongratulationsBlock from './CongratulationsBlock'
import PrivacyModal from './PrivacyModal'
import TermsModal from './TermsModal'

import './OwnerInfo.scss'

const { firstName, lastName, email, phoneNumber, birthDate } =
  OWNER_INFO_FIELD_NAMES_MAP

const { owners } = FORM_FIELD_NAMES_MAP

const OwnerInfo: FC<IStepProps> = ({ next, form }) => {
  const [modalToShow, setModalToShow] = useState<'terms' | 'policy' | ''>('')
  const queryClient = useQueryClient()

  const firstNameValue = useWatch([owners, 0, firstName], form)
  const lastNameValue = useWatch([owners, 0, lastName], form)
  const emailValue = useWatch([owners, 0, email], form)
  const phoneNumberValue = useWatch([owners, 0, phoneNumber], form)
  const birthDateValue = useWatch([owners, 0, birthDate], form)
  const termsCheckboxValue = useWatch('terms', form)
  const mailingCheckBoxValue = useWatch('mailing', form)

  useSetPrefilledData(
    form,
    [firstName, lastName, email, birthDate],
    'owners',
    0
  )

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const onPatch = async ({ owners = [] }) => {
    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/owners',
          value: owners.map((owner: TFormOwnerField) => ({
            ...owner,
            phoneNumber: getFormattedPhoneNumber(owner.phoneNumber)
          }))
        }
      ])
    })
  }

  const [patch, isLoading] = usePatchApplication(next, onPatch)

  const modalProps = {
    onCancel: () => setModalToShow(''),
    destroyOnClose: true,
    footer: null,
    centered: true
  } as const

  const buttonProps = {
    size: 'small',
    type: 'link',
    style: { padding: 0, textDecoration: 'underline' }
  } as const

  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )
  const partnerName = data?.name

  return (
    <>
      <div className={'owner-info form-step'}>
        <CongratulationsBlock />
        <h2 className={'owner-info__title'}>Owner information</h2>
        <p className={'owner-info__subtitle'}>
          Please provide a few details about the primary owner contact
          information
        </p>
        <Form.Item
          name={[owners, 0, firstName]}
          className={'owner-info__input'}
          label='First Name'
          rules={[
            {
              required: true,
              message: 'The first name is required',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='First Name' />
        </Form.Item>
        <Form.Item
          name={[owners, 0, lastName]}
          label='Last Name'
          className={'owner-info__input'}
          rules={[
            {
              required: true,
              message: 'The last name is required',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='Last Name' />
        </Form.Item>
        <Form.Item
          name={[owners, 0, email]}
          label='Email'
          className={'owner-info__input-with-prefix'}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'The email should include “@” and “.”'
            }
          ]}
        >
          <Input
            placeholder='name@email.com'
            type='email'
            prefix={<img src={mailIcon} alt={'mail-icon'} />}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <FormPhoneInput
              fieldName={[owners, 0, phoneNumber]}
              className={'owner-info__input-with-prefix'}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={[owners, 0, birthDate]}
              label='Date of Birth'
              rules={[
                {
                  required: true,
                  message: 'Please enter a Date'
                }
              ]}
              className={'owner-info__date-wrapper'}
            >
              <DatePicker
                style={{ display: 'flex' }}
                autoComplete="off"
                format={DEFAULT_DATE_FORMAT}
                placeholder={DEFAULT_DATE_FORMAT_PLACEHOLDER}
                suffixIcon={<img src={calendarIcon} alt={'input-icon'} />}
                disabledDate={(currentDate) => {
                  return currentDate && currentDate > dayjs().endOf('day')
                }}
                className={'owner-info__date'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name='terms'
          valuePropName='checked'
          className={'owner-info__checkbox'}
        >
          <Checkbox>
            You consent to the {partnerName}&nbsp;
            <Button onClick={() => setModalToShow('terms')} {...buttonProps}>
              Terms of Service
            </Button>
            &nbsp;and&nbsp;
            <Button onClick={() => setModalToShow('policy')} {...buttonProps}>
              Privacy Policy
            </Button>
            . You acknowledge and agree that the information you provide to{' '}
            {partnerName} will be shared with potential lenders or funding
            providers and that representatives of {partnerName} may contact you
            using the contact information you have provided including by email,
            autodialed and prerecorded calls regarding your interest in lending
            or financing products and offers
          </Checkbox>
        </Form.Item>
        <Form.Item
          valuePropName='checked'
          name='mailing'
          className={'owner-info__checkbox'}
        >
          <Checkbox>
            You consent to receive text messages from {partnerName} regarding
            your interest in its products. Message & data rates may apply.
            Message frequency varies. Unsubscribe at any time by replying STOP.
            Text HELP for help. You further acknowledge that your consent to
            these communications is not a condition of any purchase.
          </Checkbox>
        </Form.Item>
        <Button
          loading={isLoading}
          type={'primary'}
          onClick={() => {
            patch()
          }}
          className={'owner-info__next'}
          disabled={
            !(
              firstNameValue?.length &&
              lastNameValue?.length &&
              emailValue &&
              phoneNumberValue?.valid() &&
              birthDateValue &&
              termsCheckboxValue &&
              mailingCheckBoxValue
            )
          }
        >
          Next
        </Button>
      </div>
      <TermsModal open={modalToShow === 'terms'} {...modalProps} />
      <PrivacyModal open={modalToShow === 'policy'} {...modalProps} />
    </>
  )
}

export default OwnerInfo
