import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StrepFourDesktop = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status)

  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M9.5 15.3327C9.5 12.19 9.5 10.6186 10.4763 9.64233C11.4526 8.66602 13.024 8.66602 16.1667 8.66602H17.8333C20.976 8.66602 22.5474 8.66602 23.5237 9.64233C24.5 10.6186 24.5 12.19 24.5 15.3327V18.666C24.5 21.8087 24.5 23.3801 23.5237 24.3564C22.5474 25.3327 20.976 25.3327 17.8333 25.3327H16.1667C13.024 25.3327 11.4526 25.3327 10.4763 24.3564C9.5 23.3801 9.5 21.8087 9.5 18.666V15.3327Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M13.667 17H20.3337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M13.667 13.666H20.3337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M13.667 20.334H17.8337'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default StrepFourDesktop
